const BUIDL_TYPE_DEV = 'dev';
const BUIDL_TYPE_STAGING = 'staging';
const BUILD_TYPE_PROD = 'prod';
const BUILD_TYPE_PROD2 = 'prod2';

/* THE Botlogy Development firebase config */
const devConfig = {
  apiKey: 'AIzaSyAgwd9WIKamTioyOZNZLBiD7oJQRKBF6Is',
  authDomain: 'botlogy-development.firebaseapp.com',
  databaseURL: 'https://botlogy-development.firebaseio.com',
  projectId: 'botlogy-development',
  storageBucket: 'botlogy-development.appspot.com',
  messagingSenderId: '551653685430',
  appId: '1:551653685430:web:df5dad5500cdda66550e17',
  measurementId: 'G-DV2L3MKXCF',
};

/* THE Botlogy Experiment firebase config */
const stagingConfig = {
  apiKey: 'AIzaSyCWE575Izc_A8qoiqbhO8V3cxBN0fCeGmc',
  authDomain: 'botlogy-experiment.firebaseapp.com',
  databaseURL: 'https://botlogy-experiment.firebaseio.com',
  projectId: 'botlogy-experiment',
  storageBucket: 'botlogy-experiment.appspot.com',
  messagingSenderId: '747409680543',
  appId: '1:747409680543:web:a542224b9f4cfc5d',
  measurementId: 'G-TDPGDEE0W0',
};

/* THE Botlogy firebase config */
const prodConfig = {
  apiKey: 'AIzaSyAyKq60XfTfLlL4L0LNF_m8bWxnjCG03p8',
  authDomain: 'the-botlogy.firebaseapp.com',
  databaseURL: 'https://the-botlogy.firebaseio.com',
  projectId: 'the-botlogy',
  storageBucket: 'the-botlogy.appspot.com',
  messagingSenderId: '46594842902',
  appId: '1:46594842902:web:85c200f7cb84ed35a47037',
  measurementId: 'G-BR7EMKZH80',
};

/* THE Botlogy2 firebase config */
const prod2Config = {
  apiKey: 'AIzaSyDNlt-_c8izDyApe7B1Ioq70mHK4d2RujE',
  authDomain: 'the-botlogy2.firebaseapp.com',
  databaseURL: 'https://the-botlogy2.firebaseio.com',
  projectId: 'the-botlogy2',
  storageBucket: 'the-botlogy2.appspot.com',
  messagingSenderId: '847351543112',
  appId: '1:847351543112:web:12e90303f7147cd2',
  measurementId: 'G-Q84ZK1VXWK',
};

const configs = new Map<string | undefined, Object>([
  [BUIDL_TYPE_DEV, devConfig],
  [BUIDL_TYPE_STAGING, stagingConfig],
  [BUILD_TYPE_PROD, prodConfig],
  [BUILD_TYPE_PROD2, prod2Config],
  [undefined, devConfig],
]);

const firebaseConfig = configs.get(process.env.REACT_APP_BUILD_TYPE);

export const isDev = process.env.REACT_APP_BUILD_TYPE === BUIDL_TYPE_DEV;
export const isStaging =
  process.env.REACT_APP_BUILD_TYPE === BUIDL_TYPE_STAGING;
export const isProd = process.env.REACT_APP_BUILD_TYPE === BUILD_TYPE_PROD;
export const isProd2 = process.env.REACT_APP_BUILD_TYPE === BUILD_TYPE_PROD2;

export default firebaseConfig;
