import { OpeningStatus, ITimeTableRow, ITimes, DAYS } from './scenario.state';
import { Moment } from 'moment';
import { VariableType, IScenarioVariable } from '@botlogy/scenario-editor';

/* greetingMessage 
 --------------------------*/

const GREETING_MESSAGE_TEMPLATE = `相談を開始するときは、メニューの「相談する」を押してください！`;

export const generateGreetingMessage = (message: string) =>
  `${message}
${GREETING_MESSAGE_TEMPLATE}`;

/* timetable
 -------------------------*/
export const toggleOpeningStatus = (status: OpeningStatus) =>
  status === OpeningStatus.Open ? OpeningStatus.Close : OpeningStatus.Open;

export const toggleUateOpeningStatusByIndex = (
  row: OpeningStatus[],
  index: number,
) => {
  return row.map((status, i) =>
    i === index ? toggleOpeningStatus(status) : status,
  );
};

export type UpdateTimeTableRowHandler = (
  timeTableRow: ITimeTableRow,
) => ITimeTableRow;

export const updateTimeTableRowByIndex = (
  timeTableRows: ITimeTableRow[],
  index: number,
  update: Partial<ITimeTableRow> | UpdateTimeTableRowHandler,
): ITimeTableRow[] => {
  const handler =
    typeof update === 'function'
      ? (r: ITimeTableRow, i: number) => (i !== index ? r : update(r))
      : (r: ITimeTableRow, i: number) => (i !== index ? r : { ...r, update });

  return timeTableRows.map(handler);
};

export const updateTimeInTimeTableRow = (
  row: ITimeTableRow,
  update: Partial<ITimes>,
): ITimeTableRow => {
  return {
    ...row,
    times: {
      ...row.times,
      ...update,
    },
  };
};

export const formatOpeningTime = (moment: Moment) => {
  return moment.format('HH:mm');
};

const toTimeStr = (times: ITimes) => {
  return `${times.openingTime}~${times.closingTime}`;
};

type TimTableColumn = {
  isOpen: boolean;
  timeStr: string;
}[];

export const timeTableRowsToColumns = (
  timeTableRows: ITimeTableRow[],
): TimTableColumn[] => {
  return Array.from({ length: DAYS.length }, (_, i) =>
    timeTableRows.map(({ days, times }) => ({
      isOpen: days[i] === OpeningStatus.Open,
      timeStr: toTimeStr(times),
    })),
  );
};

export const columToTimeStr = (colum: TimTableColumn): string => {
  return colum.reduce((acc: string, { isOpen, timeStr }, index) => {
    const isLast = index === colum.length - 1;
    return isOpen ? `${acc}${timeStr}${isLast ? '' : ','} ` : acc;
  }, '');
};

/* variables
 -------------------------*/
const VARIABLE_LABELS: { [key: string]: string } = {
  clinicName: 'クリニック名',
  websiteUrl: 'ウェブサイト',
  appointmentLabel: '予約ラベル',
  appointmentValue: '予約アクション',
  phoneNumber: '電話番号',
};
const DEFAULT_LABEL = '未設定';

const APPOINTMENT_URL_LABEL = 'オンライン予約はこちらから';
const APPOINTMENT_PHONE_LABEL = '予約のお電話はこちら';

// 予約URLの有無によって予約ボタンのラベル、アクションの変数を切り替え
export const convertAppoinmentVariables = (
  hasAppointmentUrl: boolean,
  appointmentUrl: string,
  phoneNumber: string,
): {
  appointmentLabel: string;
  appointmentValue: string;
} => {
  const appointmentLabel = hasAppointmentUrl
    ? APPOINTMENT_URL_LABEL
    : APPOINTMENT_PHONE_LABEL;
  const appointmentValue = hasAppointmentUrl
    ? appointmentUrl
    : `tel:${phoneNumber}`;
  return {
    appointmentLabel,
    appointmentValue,
  };
};

const DEFAULT_URL_VARIABLE_KEYS = ['websiteUrl', 'appointmentValue'];

export const isUrlVariable = (key: string) =>
  DEFAULT_URL_VARIABLE_KEYS.includes(key);

export const convertVariableMapToArray = (variableMap: {
  [key: string]: string;
}): IScenarioVariable[] =>
  Object.entries(variableMap).map(([key, value]) => ({
    type: isUrlVariable(key) ? VariableType.Url : VariableType.Text,
    key,
    value,
    label: VARIABLE_LABELS[key] || DEFAULT_LABEL,
  }));

export const convertVariablesToMap = (variables: IScenarioVariable[]) =>
  variables.reduce((map: { [key: string]: string }, variable) => {
    map[variable.key] = variable.value;
    return map;
  }, {});
