import { reducerWithInitialState } from 'typescript-fsa-reducers';
import actions from './referrer.actions';
import {
  IReferrerState,
  IReferrer,
  INITIAL_REFERRER_STATE,
} from './referrer.state';

/* ===================================================
	HANDLER
====================================================== */

interface IActionHandler<T = void> {
  (state: IReferrerState, paylod: T): IReferrerState;
}

const handleSetReferrers: IActionHandler<IReferrer[]> = (state, referrers) => ({
  ...state,
  referrers,
});

/* ===================================================
	REDUCER
====================================================== */

const referrerReducer = reducerWithInitialState(INITIAL_REFERRER_STATE).case(
  actions.setReferrers,
  handleSetReferrers,
);

export default referrerReducer;
