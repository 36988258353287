import { auth } from '../startup/firebase.startup';
import {
  LOG_LEVELS,
  LOG_TYPES,
  LOG_COLLECTION_NAMES,
  SYSTEM_LOG_COLLECTION_NAME,
  ILineBotManagerLogDocument,
} from '../constants/log.const';
import { UAParser } from 'ua-parser-js';
import FatalError from '../errors/fatal.error';
import {
  getAccountDocRef,
  getNewBotlogyDocRef,
  getServerTimestamp,
} from './firebase.util';

export const parseUserAgent = () => {
  const userAgent = new UAParser().getResult();

  // convert undefined to null (firebase dose not allow undefined)
  Object.values(userAgent).forEach(value1 => {
    if (typeof value1 === 'string') return;
    Object.entries(value1).forEach(([key, value2]) => {
      if (!value2) value1[key] = null;
    });
  });

  return userAgent;
};

export const writeLog = async (log: Partial<ILineBotManagerLogDocument>) => {
  if (!log.type) throw new Error(`log type must be set !!!!`);

  const colRef = auth.currentUser
    ? getAccountDocRef(auth.currentUser.uid).collection(
        LOG_COLLECTION_NAMES[log.type],
      )
    : getNewBotlogyDocRef().collection(SYSTEM_LOG_COLLECTION_NAME);

  try {
    await colRef.add(log);
  } catch (e) {
    console.error(e);
  }
};

export const generateLogObj = (): Partial<ILineBotManagerLogDocument> => {
  const userAgent = parseUserAgent();
  return {
    createdAt: getServerTimestamp(),
    userAgent,
  };
};

const logger = {
  fatalError(
    message: string,
    error?: FatalError | null,
    info?: React.ErrorInfo | null,
  ) {
    if (!message) throw new Error('message needs to be provided.');
    if (!error) error = null;
    if (!info) info = null;

    const log = generateLogObj();
    log.message = message;
    log.details = { error: parseCustomObj(error), info: parseCustomObj(info) };
    log.level = LOG_LEVELS.FATAL_ERROR;
    log.type = LOG_TYPES[log.level];
    return writeLog(log);
  },
  error(message: string, error?: Error | null, info?: React.ErrorInfo | null) {
    if (!message) throw new Error('message needs to be provided.');
    if (!error) error = null;
    if (!info) info = null;

    const log = generateLogObj();
    log.message = message;
    log.details = { error: parseCustomObj(error), info: parseCustomObj(info) };
    log.level = LOG_LEVELS.ERROR;
    log.type = LOG_TYPES[log.level];
    return writeLog(log);
  },
  warn(message: string) {
    if (!message) throw new Error('message needs to be provided.');

    const log = generateLogObj();
    log.message = message;
    log.level = LOG_LEVELS.WARN;
    log.type = LOG_TYPES[LOG_LEVELS.WARN];
    return writeLog(log);
  },
  info(message: string) {
    if (!message) throw new Error('message needs to be provided.');

    const log = generateLogObj();
    log.message = message;
    log.level = LOG_LEVELS.INFO;
    log.type = LOG_TYPES[LOG_LEVELS.INFO];
    return writeLog(log);
  },
};

// it is not allowed to store Instantialted custom object in firestore, so convert it to plain object
const parseCustomObj = (obj: any) => {
  return Object.assign({}, obj);
};

export default logger;
