export const PASSWORD_MIN_LENGTH = 6;
export const CLINIC_NAME_MAX_LENGTH = 50;
export const ACCOUNT_NAME_MAX_LENGTH = 20;
export const ACCESS_TOKEN_MAX_LENGTH = 200;
export const GREETING_MESSAGE_MAX_LENGTH = 500;
export const PREMIUM_LIN_ACCOUNT_ID_MAX_LENGTH = 18;
export const EXPLANATION_MAX_LENGTH = 250;
export const ACCESS_MAX_LENGTH = 300;
export const PARKING_OTHER_MAX_LENGTH = 100;
export const CARD_HOLDER_NAME_MAX_LENGTH = 250;
export const CAMPAIGN_CODE_MAX_LENGTH = 250;
export const SCENARIO_LABEL_MAX_LENGTH = 20;
export const VARIABLE_LABEL_MAX_LENGTH = 20;
export const VARIABLE_KEY_MAX_LENGTH = 20;
export const VARIABLE_VALUE_MAX_LENGTH = 50;
export const IMAGE_MAX_LENGTH = 12;
