import { reducerWithInitialState } from 'typescript-fsa-reducers';
import actions, { IUpdateImageNamePayload } from './scenario.actions';
import {
  INITIAL_SCENARIO_STATE,
  IScenarioState,
  initialAccess,
  initialTimeTableRow,
  ITimeTableRow,
  TimeKeyName,
  ParkingValue,
  CreateScenarioStep,
  HasAppointmentUrlValue,
  IScenarioBasicInfo,
  IScenarioDesign,
} from './scenario.state';
import {
  isValidGreetingMessage,
  isValidPremiumAccountId,
  isValidUrl,
  isValidExplanation,
  isValidAccess,
  isValidParkingOther,
  isValidName,
  isValidPhoneNumber,
} from '../../utils/validation.utils';
import {
  toggleUateOpeningStatusByIndex,
  updateTimeTableRowByIndex,
  UpdateTimeTableRowHandler,
  updateTimeInTimeTableRow,
} from './scenario.helper';
import {
  FirebaseDocument,
  IColorTemplateDocument,
  IImage,
} from '../../utils/firebase.util';
import {
  INVALID_VALUE_EXIST_MESSAGE,
  INVALID_CHECKED_TEMPLATE_OPTIONS_MESSAGE,
  INVALID_SELECTED_COLOR_TEMPLATE_MESSAGE,
} from '../../constants/error-message.const';

/* ===================================================
	CONSTANTS
====================================================== */
export const TIME_TABLE_ROWS_MAX_LENGTH = 4;

/* ===================================================
	HANDLER
====================================================== */

interface IActionHandler<T = void> {
  (state: IScenarioState, paylod: T): IScenarioState;
}

/* SCENARIO TEMPLATE DATE (from firestore)
=================================================*/

// const handleSetScenarioTemplateState: IActionHandler<IScenarioTemplateState> = (
//   state,
//   scenarioTemplateState,
// ) => ({
//   ...state,
//   ...scenarioTemplateState,
// });

// const handleSetScenarioTemplate: IActionHandler<{
//   scenarioTemplate: Template;
//   scenarioTemplateTopId: string;
// }> = (state, { scenarioTemplate, scenarioTemplateTopId }) => ({
//   ...state,
//   scenarioTemplate,
//   scenarioTemplateTopId,
// });

// const handleSetScenarioTemplateOptions: IActionHandler<CheckBoxNode[]> = (
//   state,
//   scenarioTemplateOptions,
// ) => ({
//   ...state,
//   scenarioTemplateOptions,
// });

/* SCENARIO CLINIC FORM (STEP1)
=================================================*/

/* greetingMessage */
const handleSetHandleGreetingMessage: IActionHandler<string> = (
  state,
  greetingMessage,
) => {
  const { error } = isValidGreetingMessage(greetingMessage);
  const greetingMessageHelperText = error ? error : '';
  return {
    ...state,
    greetingMessage,
    greetingMessageHelperText,
  };
};

/* premiumLineAccountId */
const handleSetPremiumLineAccountId: IActionHandler<string> = (
  state,
  premiumLineAccountId,
) => {
  const { error } = isValidPremiumAccountId(premiumLineAccountId);
  const premiumLineAccountIdHelperText = error ? error : '';
  return {
    ...state,
    premiumLineAccountId,
    premiumLineAccountIdHelperText,
  };
};

/* websiteUrl */
const handleSetWebsiteUrl: IActionHandler<string> = (state, websiteUrl) => {
  const { error } = isValidUrl(websiteUrl);
  const websiteUrlHelperText = error ? error : '';
  return {
    ...state,
    websiteUrl,
    websiteUrlHelperText,
  };
};

/* appointmentUrl */
const handleSetHasAppointmentUrl: IActionHandler<HasAppointmentUrlValue> = (
  state,
  hasAppointmentUrl,
) => ({
  ...state,
  hasAppointmentUrl,
});

const handleSetAppointmentUrl: IActionHandler<string> = (
  state,
  appointmentUrl,
) => {
  const { error } = isValidUrl(appointmentUrl);
  const appointmentUrlHelperText = error ? error : '';

  return {
    ...state,
    appointmentUrl,
    appointmentUrlHelperText,
  };
};

/* parking */
const handleSetParking: IActionHandler<ParkingValue> = (state, parking) => ({
  ...state,
  parking,
  parkingOtherHelperText: '',
});

const handleSetParkingOther: IActionHandler<string> = (state, parkingOther) => {
  const { error } = isValidParkingOther(parkingOther);
  const parkingOtherHelperText = error ? error : '';

  return {
    ...state,
    parkingOther,
    parkingOtherHelperText,
  };
};

/* acesses 
-------------------------------*/
const handleSetAccessWithIndex: IActionHandler<{
  index: number;
  value: string;
}> = (state, { index, value }) => {
  const access = state.accesses[index];
  if (!access) return state;

  const { error } = isValidAccess(value);
  access.value = value;
  access.helperText = error ? error : '';

  return {
    ...state,
    accesses: [...state.accesses],
  };
};

const handleAddAccess: IActionHandler = state => {
  return {
    ...state,
    accesses: [...state.accesses, { ...initialAccess }],
  };
};

const handleRemoveAccess: IActionHandler<number> = (state, index) => {
  if (index === 0) return state;

  return {
    ...state,
    accesses: state.accesses.filter((_, i) => i !== index),
  };
};

/* timeTable  
-------------------------------*/
const handleAddTimeTableRow: IActionHandler = state => {
  if (state.timeTableRows.length >= TIME_TABLE_ROWS_MAX_LENGTH) return state;

  return {
    ...state,
    timeTableRows: [...state.timeTableRows, { ...initialTimeTableRow }],
  };
};

const handleRemoveTimeTableRow: IActionHandler<number> = (state, rowIndex) => {
  if (state.timeTableRows.length === 1) return state;

  const newRow = state.timeTableRows.filter((_, i) => i !== rowIndex);
  return {
    ...state,
    timeTableRows: newRow,
  };
};

const handleUpdateTimeTableRow: IActionHandler<{
  rowIndex: number;
  row: ITimeTableRow;
}> = (state, { rowIndex, row }) => {
  const { timeTableRows } = state;
  if (!timeTableRows[rowIndex]) return state;

  return {
    ...state,
    timeTableRows: updateTimeTableRowByIndex(timeTableRows, rowIndex, row),
  };
};

const handleToggleOpeningStatus: IActionHandler<{
  rowIndex: number;
  dayIndex: number;
}> = (state, { rowIndex, dayIndex }) => {
  const { timeTableRows } = state;
  if (!timeTableRows[rowIndex] || !timeTableRows[rowIndex].days[dayIndex])
    return state;

  const updateTimeTableRowHandler: UpdateTimeTableRowHandler = (
    row: ITimeTableRow,
  ) => ({
    ...row,
    days: toggleUateOpeningStatusByIndex(row.days, dayIndex),
  });

  return {
    ...state,
    timeTableRows: updateTimeTableRowByIndex(
      timeTableRows,
      rowIndex,
      updateTimeTableRowHandler,
    ),
  };
};

const generateSetTimeHandler = (
  timeKeyName: TimeKeyName,
): IActionHandler<{
  rowIndex: number;
  time: string;
}> => (state, { rowIndex, time }) => {
  const { timeTableRows } = state;
  if (!timeTableRows[rowIndex]) return state;

  const updateTimeTableRowHandler: UpdateTimeTableRowHandler = (
    row: ITimeTableRow,
  ) => updateTimeInTimeTableRow(row, { [timeKeyName]: time });

  return {
    ...state,
    timeTableRows: updateTimeTableRowByIndex(
      timeTableRows,
      rowIndex,
      updateTimeTableRowHandler,
    ),
  };
};

const handleSetOpeningTime = generateSetTimeHandler(TimeKeyName.OpeningTime);

const handleSetClosingTime = generateSetTimeHandler(TimeKeyName.ClosingTime);

const handleSetTimeTableExplanation: IActionHandler<string> = (
  state,
  timeTableExplanation,
) => {
  const { error } = isValidExplanation(timeTableExplanation);
  const timeTableExplanationHelperText = error ? error : '';
  return {
    ...state,
    timeTableExplanation,
    timeTableExplanationHelperText,
  };
};

/* sceanrio clinic form  
-------------------------------*/

const handleValidateScenarioClinicForm: IActionHandler = state => {
  const { error: greetingMessageError } = isValidGreetingMessage(
    state.greetingMessage,
  );
  const greetingMessageHelperText = greetingMessageError
    ? greetingMessageError
    : '';

  const { error: premiumLineAccountIdError } = isValidPremiumAccountId(
    state.premiumLineAccountId,
  );
  const premiumLineAccountIdHelperText = premiumLineAccountIdError
    ? premiumLineAccountIdError
    : '';

  const { error: websiteUrlError } = isValidUrl(state.websiteUrl);
  const websiteUrlHelperText = websiteUrlError ? websiteUrlError : '';

  const { error: timeTableExplanationError } = isValidExplanation(
    state.timeTableExplanation,
  );
  const timeTableExplanationHelperText = timeTableExplanationError
    ? timeTableExplanationError
    : '';

  const newAccesses = state.accesses.map(({ value }) => {
    const { error } = isValidAccess(value);
    return {
      value,
      helperText: error ? error : '',
    };
  });

  let appointmentUrlHelperText = '';
  if (state.hasAppointmentUrl === HasAppointmentUrlValue.On) {
    const { error } = isValidUrl(state.appointmentUrl);
    appointmentUrlHelperText = error ? error : '';
  }

  let parkingOtherHelperText = '';
  if (state.parking === ParkingValue.Other) {
    const { error } = isValidParkingOther(state.parkingOther);
    parkingOtherHelperText = error ? error : '';
  }

  const isScenarioClinicFormComplete =
    !greetingMessageHelperText &&
    !premiumLineAccountIdHelperText &&
    !websiteUrlHelperText &&
    !timeTableExplanationHelperText &&
    newAccesses.every(({ helperText }) => !helperText) &&
    !appointmentUrlHelperText &&
    !parkingOtherHelperText;

  const scenarioClinicFormInvalidMessage = !isScenarioClinicFormComplete
    ? INVALID_VALUE_EXIST_MESSAGE
    : '';

  return {
    ...state,
    isScenarioClinicFormComplete,
    greetingMessageHelperText,
    premiumLineAccountIdHelperText,
    websiteUrlHelperText,
    timeTableExplanationHelperText,
    appointmentUrlHelperText,
    parkingOtherHelperText,
    accesses: newAccesses,
    scenarioClinicFormInvalidMessage,
  };
};

const handleSetCreateScenarioStep: IActionHandler<CreateScenarioStep> = (
  state,
  step,
) => ({
  ...state,
  step,
});

const handleSetIsScenarioClinicFormComplete: IActionHandler<boolean> = (
  state,
  isScenarioClinicFormComplete,
) => ({
  ...state,
  isScenarioClinicFormComplete,
});

/* SCENARIO TEMPLATE FORM (STEP2)    
=================================================*/

/* clinicName
-------------------------------*/
const handleSetClinicName: IActionHandler<string> = (state, clinicName) => {
  const { error } = isValidName(clinicName);
  const clinicNameHelperText = error ? error : '';

  return {
    ...state,
    clinicName,
    clinicNameHelperText,
  };
};

/* phoneNumber
-------------------------------*/
const handleSetPhoneNumber: IActionHandler<string> = (state, phoneNumber) => {
  const { error } = isValidPhoneNumber(phoneNumber);
  const phoneNumberHelperText = error ? error : '';

  return {
    ...state,
    phoneNumber,
    phoneNumberHelperText,
  };
};

/* colorTemplate
-------------------------------*/

const handleSetColorTemplate: IActionHandler<FirebaseDocument<
  IColorTemplateDocument
>> = (state, colorTemplate) => {
  const selectedColorTemplateHelperText = colorTemplate
    ? ''
    : state.selectedColorTemplateHelperText;

  return {
    ...state,
    selectedColorTemplate: colorTemplate,
    selectedColorTemplateHelperText,
  };
};

/* template options
-------------------------------*/

const handleSetCheckedTemplateOptions: IActionHandler<string[]> = (
  state,
  checkedTemplateOptions,
) => {
  const checkedTemplateOptionsHelperText =
    checkedTemplateOptions.length > 0
      ? ''
      : state.checkedTemplateOptionsHelperText;

  return {
    ...state,
    checkedTemplateOptions,
    checkedTemplateOptionsHelperText,
  };
};

const handleValidateScenarioTemplateForm: IActionHandler = state => {
  /* options */
  const isTemplateOptionsChecked =
    state.checkedTemplateOptions && state.checkedTemplateOptions.length >= 1;
  const checkedTemplateOptionsHelperText = !isTemplateOptionsChecked
    ? INVALID_CHECKED_TEMPLATE_OPTIONS_MESSAGE
    : '';

  /* clinicName */
  const { error: clinicNameError } = isValidName(state.clinicName);
  const clinicNameHelperText = clinicNameError ? clinicNameError : '';

  /* phoneNumber */
  const { error: phoneNumberError } = isValidPhoneNumber(state.phoneNumber);
  const phoneNumberHelperText = phoneNumberError ? phoneNumberError : '';

  /* colorTemplate */
  const isColorTemplateSelected = !!state.selectedColorTemplate;
  const selectedColorTemplateHelperText = !isColorTemplateSelected
    ? INVALID_SELECTED_COLOR_TEMPLATE_MESSAGE
    : '';

  const isScenarioTemplateFormComplete =
    !checkedTemplateOptionsHelperText &&
    !clinicNameHelperText &&
    !phoneNumberHelperText &&
    !selectedColorTemplateHelperText;

  const scenarioTemplateFormInvalidMessage = !isScenarioTemplateFormComplete
    ? INVALID_VALUE_EXIST_MESSAGE
    : '';

  return {
    ...state,
    checkedTemplateOptionsHelperText,
    clinicNameHelperText,
    phoneNumberHelperText,
    selectedColorTemplateHelperText,
    isScenarioTemplateFormComplete,
    scenarioTemplateFormInvalidMessage,
  };
};

const handleSetIsScenarioTempalteFormComplete: IActionHandler<boolean> = (
  state,
  isScenarioTemplateFormComplete,
) => ({
  ...state,
  isScenarioTemplateFormComplete,
});

/* basicInfo
-------------------------------*/

const hanldeSetScenarioBasicInfo: IActionHandler<IScenarioBasicInfo> = (
  state,
  basicInfo,
) => ({
  ...state,
  ...basicInfo,
});

/* scenarioDesign
-------------------------------*/

const handleSetScenarioDesign: IActionHandler<IScenarioDesign> = (
  state,
  scenarioDesign,
) => ({
  ...state,
  scenarioDesign,
  selectedColorTemplate: undefined,
});

/* images
-------------------------------*/

const handleSetImages: IActionHandler<IImage[]> = (state, images) => ({
  ...state,
  images,
});

const handleUpdateImageName: IActionHandler<IUpdateImageNamePayload> = (
  state,
  { id, name },
) => ({
  ...state,
  images: state.images.map(image =>
    image.id === id ? { ...image, name } : image,
  ),
});

/* ===================================================
	REDUCER
====================================================== */

const scenarioReducer = reducerWithInitialState(INITIAL_SCENARIO_STATE)
  /* SCENARIO TEMPLATE DATE (from firestore)
	=================================================*/
  // .case(actions.setScenarioTemplateState, handleSetScenarioTemplateState)

  // .case(actions.setScenarioTemplate, handleSetScenarioTemplate)

  // .case(actions.setScenarioTemplateOptions, handleSetScenarioTemplateOptions)

  /* SCENARIO FORM (OVERALL)
	=================================================*/

  .case(actions.setCreateScenarioStep, handleSetCreateScenarioStep)

  /* SCENARIO CLINIC FORM (STEP1)
	=================================================*/

  /* greetingMessage */
  .case(actions.setGreetingMessage, handleSetHandleGreetingMessage)

  /* premiumLineAccountId */
  .case(actions.setPremiumLineAccountId, handleSetPremiumLineAccountId)

  /* websiteUrl */
  .case(actions.setWebsiteUrl, handleSetWebsiteUrl)

  /* appointmentUrl 
	---------------------*/
  .case(actions.setHasAppointmentUrl, handleSetHasAppointmentUrl)

  .case(actions.setAppointmentUrl, handleSetAppointmentUrl)

  /* parking */
  .case(actions.setParking, handleSetParking)

  .case(actions.setParkingOther, handleSetParkingOther)

  /* acesses */
  .case(actions.setAccessWithIndex, handleSetAccessWithIndex)

  .case(actions.addAccess, handleAddAccess)

  .case(actions.removeAccess, handleRemoveAccess)

  /* timeTable */
  .case(actions.addTimeTableRow, handleAddTimeTableRow)

  .case(actions.removeTimeTableRow, handleRemoveTimeTableRow)

  .case(actions.updateTimeTableRow, handleUpdateTimeTableRow)

  .case(actions.toggleOpeningStatus, handleToggleOpeningStatus)

  .case(actions.setOpeningTime, handleSetOpeningTime)

  .case(actions.setClosingTime, handleSetClosingTime)

  .case(actions.setTimeTableExplanation, handleSetTimeTableExplanation)

  /* scenario clinic form */
  .case(actions.validateScenarioClinicForm, handleValidateScenarioClinicForm)

  .case(
    actions.setIsScenarioClinicFormComplete,
    handleSetIsScenarioClinicFormComplete,
  )

  /* SCENARIO TEMPLATE FORM (STEP2)    
	=================================================*/

  /* clinicName 
	---------------------*/
  .case(actions.setClinicName, handleSetClinicName)

  /* phoneNumber 
	----------------------*/
  .case(actions.setPhoneNumber, handleSetPhoneNumber)

  /* colorTemplate 
	----------------------*/
  .case(actions.setColorTemmplate, handleSetColorTemplate)

  /* template options 
	----------------------*/
  .case(actions.setCheckedTemplateOptions, handleSetCheckedTemplateOptions)

  /* scenario template form
	-------------------------*/
  .case(
    actions.validateScenarioTemplateForm,
    handleValidateScenarioTemplateForm,
  )

  .case(
    actions.setIsScenarioTemplateFormComplete,
    handleSetIsScenarioTempalteFormComplete,
  )

  /* SCENARIO BASIC INFO  (scenario-management-page)    
=================================================*/

  .case(actions.setScenarioBasicInfo, hanldeSetScenarioBasicInfo)

  /* SCENARIO DESIGN  (scenario-management-page)    
=================================================*/

  .case(actions.setScenarioDesign, handleSetScenarioDesign)

  /* IMAGES  (scenario-management-page)    
=================================================*/

  .case(actions.setImages, handleSetImages)

  .case(actions.updateImageName, handleUpdateImageName);

export default scenarioReducer;
