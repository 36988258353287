import React, { Component } from 'react';

import logger from '../../utils/log.util';

import ErrorScreen from './error-screen.component';
import { ERROR_BOUNDARY_CATCH_ERROR_MESSAGE } from '../../constants/log.const';
import FatalError from '../../errors/fatal.error';
import { analytics } from '../../startup/firebase.startup';

interface IErrorBoundaryState {
  hasError: boolean;
}

class ErrorBoundary extends Component<{}, IErrorBoundaryState> {
  constructor(props: {}) {
    super(props);
    this.state = { hasError: false };
  }
  static getDerivedStateFromError() {
    return { hasError: true };
  }
  componentDidCatch(error: Error | FatalError, info: React.ErrorInfo) {
    const isFatal = error instanceof FatalError;

    /* [EVENT] log exception event */
    analytics.logEvent('exception', {
      description: error.message,
      fatal: isFatal,
    });

    if (isFatal) {
      logger.fatalError(
        `${ERROR_BOUNDARY_CATCH_ERROR_MESSAGE} ${
          error.message ? `>>> ${error.message}` : ''
        }`,
        error as FatalError,
        info,
      );
    } else {
      logger.error(
        `${ERROR_BOUNDARY_CATCH_ERROR_MESSAGE} ${
          error.message ? `>>> ${error.message}` : ''
        }`,
        error,
        info,
      );
    }
  }
  render() {
    if (this.state.hasError) {
      return <ErrorScreen isFatal={true} />;
    }

    return this.props.children;
  }
}

export default ErrorBoundary;
