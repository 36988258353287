import React from 'react';
import {
  BrowserRouter as Router,
  Switch,
  Route,
  useLocation,
} from 'react-router-dom';

// import { setAuthPersistanceToSession } from './utils/firebase.util';

import ErrorBoundary from './components/error-boundary/error-boundary.component';
import { isProd } from './config/firebase.config';
// import { AppProvider } from './contexts/app/app.context';
// import AppRoutes from './routes/app/app.routes';
// import QrRedirectPage from './pages/qr-redirect-page/qr-redirect-page.component';
import {
  QR_REDIRECT_PATH,
  REDIRECT_ROUTES_PATH,
  // EMAIL_LINK_SIGN_UP_PATH,
  // EMAIL_LINK_SIGN_IN_PATH,
} from './constants/routes.const';
// import EmailLinkSignUpPage from './pages/email-link-sign-up-page/email-link-sign-up-page.component';
// import EmailLinkSignInPage from './pages/email-link-sign-in-page/email-link-sign-in-page.component';
// import RedirectRoutes from './routes/redirect/redirect.routes';
import { CompleteMigrationPage } from './pages/complete-migration-page';

const BOTLOGY2_BASE_URL = isProd
  ? `https://console.botlogy.com`
  : 'https://botlogy-development.web.app/';

/**
 * botlogy2 側 (console.botlogy.com) へ pathname, query を維持した状態で redirect
 */
const RedirectToBotlogy2 = () => {
  const location = useLocation();

  const botlogy2Url = new URL(location.pathname, BOTLOGY2_BASE_URL);
  botlogy2Url.search = location.search;
  botlogy2Url.hash = location.hash;

  window.location.href = botlogy2Url.toString();

  return null;
};

//////////////////////////////// APP

const App = () => {
  return (
    <ErrorBoundary>
      <Router>
        <Switch>
          {/**
           * /redirect, /qrRedirect は pathname, search, hash を保ったまま、botlogy2 domain へredirect
           * */}
          <Route exact path={QR_REDIRECT_PATH}>
            <RedirectToBotlogy2 />
          </Route>
          <Route path={REDIRECT_ROUTES_PATH}>
            <RedirectToBotlogy2 />
          </Route>
          {/**
           * 上記以外の route は移行完了メッセージを表示
           * */}
          <Route>
            <CompleteMigrationPage />
          </Route>
        </Switch>
      </Router>
    </ErrorBoundary>
  );
};
// const App = () => {
//   useEffect(() => {
//     // tab ごとに異なるログイン情報を保持
//     setAuthPersistanceToSession();
//   }, []);

//   return (
//     <ErrorBoundary>
//       <Router>
//         <Switch>
//           {/* onAuthStateChange observe 対象外
// 				-----------------------------------------------------------*/}
//           <Route exact path={QR_REDIRECT_PATH}>
//             <QrRedirectPage />
//           </Route>

//           <Route path={REDIRECT_ROUTES_PATH}>
//             <RedirectRoutes />
//           </Route>

//           <Route exact path={EMAIL_LINK_SIGN_UP_PATH}>
//             {/* signup 後に applicant account を作成する処理をするため */}
//             <EmailLinkSignUpPage />
//           </Route>

//           <Route exact path={EMAIL_LINK_SIGN_IN_PATH}>
//             {/* signup 後に my-page にリダイレクト */}
//             <EmailLinkSignInPage />
//           </Route>
//           {/* onAuthStateChange observe 対象
// 				-----------------------------------------------------------*/}
//           <Route>
//             <AppProvider>
//               <AppRoutes />
//             </AppProvider>
//           </Route>
//         </Switch>
//       </Router>
//     </ErrorBoundary>
//   );
// };

export default App;
