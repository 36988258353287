import firebase from 'firebase/app';
import { auth, firestore } from '../startup/firebase.startup';
import { FirebaseErrorCodes } from '../constants/firebase-error-messages.cont';
import uuidv4 from 'uuid/v4';
import {
  EMAIL_LINK_SIGN_UP_PATH,
  EMAIL_LINK_SIGN_IN_PATH,
} from '../constants/routes.const';
import {
  ICreateScenarioData,
  IScenarioBasicInfo,
  IScenarioDesign,
  IAccessInfoDocument,
} from '../redux/scenario/scenario.state';
import {
  ScenarioNodeMap,
  SearchKeywords,
  ScenarioKeywordsMap,
  IScenarioVariable,
  IGreetingScenarioNode,
} from '@botlogy/scenario-editor';
import { AccountType } from '../constants/account.const';

/* ===================================================
	AUTH RELATED
====================================================== */

export const setAuthPersistanceToSession = () => {
  return auth
    .setPersistence(firebase.auth.Auth.Persistence.SESSION)
    .catch(e => console.error(e));
};

export const sendResetPasswordEmail = (email: string) => {
  return auth.sendPasswordResetEmail(email);
};

export const existsUserWithEmail = async (email: string): Promise<boolean> => {
  try {
    await auth.signInWithEmailAndPassword(email, uuidv4());
    return true;
  } catch (e) {
    if (e.code === FirebaseErrorCodes.AUTH.NETWORK_REQUEST_FAILED) throw e;
    // if user is disabled, return true
    if (e.code === FirebaseErrorCodes.AUTH.USER_DISABLED) return true;
    return !(e.code === FirebaseErrorCodes.AUTH.USER_NOT_FOUNT);
  }
};

export const EMAIL_FOR_SIGN_IN_KEY = 'email_for_sign_in';

export const sendSignInEmail = async (email: string) => {
  const actionCodeSettings: firebase.auth.ActionCodeSettings = {
    url: `${window.location.origin}${EMAIL_LINK_SIGN_IN_PATH}`,
    handleCodeInApp: true,
  };
  await auth.sendSignInLinkToEmail(email, actionCodeSettings);
  window.localStorage.setItem(EMAIL_FOR_SIGN_IN_KEY, email);
};

export const sendSignUpEmail = async (data: IApplicantDocument) => {
  const { email, ...others } = data;
  const params = Object.entries(others).reduce(
    (acc, [key, value], i) => `${acc}${i ? '&' : ''}${key}=${value}`,
    '',
  );
  const actionCodeSettings: firebase.auth.ActionCodeSettings = {
    url: `${window.location.origin}${EMAIL_LINK_SIGN_UP_PATH}?${params}`,
    handleCodeInApp: true,
  };
  await auth.sendSignInLinkToEmail(email, actionCodeSettings);
  window.localStorage.setItem(EMAIL_FOR_SIGN_IN_KEY, email);
};

export const authenticateEmail = async (email: string) => {
  await auth.signInWithEmailLink(email, window.location.href);
  window.localStorage.removeItem(EMAIL_FOR_SIGN_IN_KEY);
};

export const authenticatePassword = async (
  email: string,
  password: string,
): Promise<boolean> => {
  try {
    await auth.signInWithEmailAndPassword(email, password);
    return true;
  } catch (e) {
    return false;
  }
};

/* helpers 
-------------------------------- */
export const getCurrentUserId = (): null | string => {
  return auth.currentUser && auth.currentUser.uid;
};

/* ===================================================
	FIRESTORE RELATED
====================================================== */

/* helpers 
-------------------------------- */

interface IFirebaseDocument {
  id: string;
}

export type FirebaseDocument<T> = T & IFirebaseDocument;

export const querySnapToDocArray = <T extends {}>(
  querySnap: firebase.firestore.QuerySnapshot,
): FirebaseDocument<T>[] => {
  if (!querySnap || !querySnap.docs || !Array.isArray(querySnap.docs)) {
    console.warn('Invalid querySnap was provided!');
    return [];
  }

  return querySnap.docs.map(docSnap => ({
    id: docSnap.id,
    ...(docSnap.data({ serverTimestamps: 'estimate' }) as T), // null防止 (see >>> https://github.com/firebase/firebase-js-sdk/issues/1929)
  }));
};

export const generateRandomDocId = () => {
  const docRef = firestore.collection('dummy').doc();
  return docRef.id;
};

// serverTimestamp() は array の中では使用できないのでその場合は getCurrentTimestamp() を使用
export const getServerTimestamp = () =>
  firebase.firestore.FieldValue.serverTimestamp() as firebase.firestore.Timestamp;

export const getCurrentTimestamp = () => firebase.firestore.Timestamp.now();

/* new-botlogy
-------------------------------- */

export enum NewBotlogyVersion {
  v1,
  v2,
  Playground,
}

export enum NewBotlogyDocumentId {
  v1 = '190926',
  v2 = '191223',
  Playground = 'playground',
}

/*= NEW BOTLOGY VERSION =============================*/
/*****************************************************
 * Version の更新する際は
 * 以下の DEFAULT_VERSION の値を変更する
 *****************************************************/
const DEFAULT_VERSION = NewBotlogyVersion.v2;
/*===================================================*/

const selectDocId = (version: NewBotlogyVersion) => {
  switch (version) {
    case NewBotlogyVersion.v1:
      return NewBotlogyDocumentId.v1;
    case NewBotlogyVersion.v2:
      return NewBotlogyDocumentId.v2;
    case NewBotlogyVersion.Playground:
      return NewBotlogyDocumentId.Playground;
    default:
      throw new Error(`Invalid version given. given version: ${version}`);
  }
};

const getNewBotlogyDocPath = (version: NewBotlogyVersion = DEFAULT_VERSION) =>
  `new-botlogy/${selectDocId(version)}`;

export const getNewBotlogyDocRef = (version?: NewBotlogyVersion) =>
  firestore.doc(getNewBotlogyDocPath(version));

interface INewBotlogyDocument {
  version: string;
  versionId: string;
  updatedAt: firebase.firestore.Timestamp;
}

export type INewBotlogyDataHandler = (
  newBotlogyDocument: INewBotlogyDocument,
) => Promise<void>;

export const updateNewBotlogy = async (version: string) => {
  try {
    await getNewBotlogyDocRef().set({
      version,
      versionId: uuidv4(),
      updatedAt: getServerTimestamp(),
    });
  } catch (e) {
    console.error(e);
  }
};

export const subscribeNewBotlogy = (handler: INewBotlogyDataHandler) => {
  return getNewBotlogyDocRef().onSnapshot(docSnap => {
    const data = docSnap.data({
      serverTimestamps: 'estimate',
    }) as INewBotlogyDocument;
    handler(data);
  });
};

/* user (accounts) 
-------------------------------- */

export interface IAccountDocument {
  name: string;
  accountNumber: string;
  accountType: AccountType;
  clinicId?: string;
  email: string;
  createdAt: firebase.firestore.Timestamp;
  createdBy: string; // uid of the creator
  stripeCustomerId?: string;
}

export const getAccountsColRef = (): firebase.firestore.CollectionReference =>
  getNewBotlogyDocRef().collection('accounts'); //TODO renew collection name in firestore

export const getAccountDocRef = (
  accountId: string,
): firebase.firestore.DocumentReference => getAccountsColRef().doc(accountId);

export const fetchAccountData = async (): Promise<IAccountDocument> => {
  const currentUserId = getCurrentUserId();
  if (!currentUserId) throw new Error('No login user!');

  const accountDocRef = getAccountDocRef(currentUserId);
  const docSnap = await accountDocRef.get();

  if (!docSnap.exists)
    throw new Error(
      `Account document with id: ${currentUserId} does not exist!!!`,
    );

  const accountData = docSnap.data() as IAccountDocument;
  if (!accountData.accountType)
    throw new Error(
      `Account type does not exist in account (id: ${docSnap.id})`,
    );

  return accountData;
};

/* applicant
-------------------------------- */
export interface IApplicantDocument {
  hospitalName: string;
  accountName: string;
  department: string;
  email: string;
  doctorName: string;
  address: string;
  phoneNumber: string;
  referrerId: string;
}

export const getApplicantsColRef = () =>
  getNewBotlogyDocRef().collection('applicants');

export const getApplicantDocRef = (accountId: string) =>
  getApplicantsColRef().doc(accountId);

export const saveApplicant = async (applicantDoc: IApplicantDocument) => {
  const currentUserId = getCurrentUserId();
  if (!currentUserId) throw new Error('No user logged in!');

  await getApplicantDocRef(currentUserId).set(applicantDoc);
};

export const fetchApplicant = async (
  accountId: string,
): Promise<IApplicantDocument> => {
  const docSnap = await getApplicantDocRef(accountId).get();
  return docSnap.data() as IApplicantDocument;
};

/* clinic 
-------------------------------- */

export interface IClinicDocument {
  name: string;
  clinicId: string; // security rules で余分な read を防ぐため
  clinicNumber: string;
  authorizedAccountIds: { [accountId: string]: boolean };
  deployedScenarioId: string;
  createdBy: string;
  createdAt: firebase.firestore.Timestamp;
}

export const getClinicsColRef = () =>
  getNewBotlogyDocRef().collection('clinics');

export const getClinicDocRef = (clinicId: string) =>
  getClinicsColRef().doc(clinicId);

export const fetchClinic = async (
  clinicId: string,
): Promise<IClinicDocument> => {
  const clinicDocRef = getClinicDocRef(clinicId);
  const docSnap = await clinicDocRef.get();
  return docSnap.data() as IClinicDocument;
};

export const fetchAuthorizedClinics = async (): Promise<IClinicDocument[]> => {
  const currentUserId = getCurrentUserId();
  if (!currentUserId) throw new Error('No user logged in!');

  const querySnap = await getClinicsColRef()
    .where(`authorizedAccountIds.${currentUserId}`, '==', true)
    .get();
  return querySnapToDocArray<IClinicDocument>(querySnap);
};

export const fetchAllClinics = async (): Promise<IClinicDocument[]> => {
  const currentUserId = getCurrentUserId();
  if (!currentUserId) throw new Error('No user logged in!');

  const querySnap = await getClinicsColRef().get();
  return querySnapToDocArray<IClinicDocument>(querySnap);
};

/* public in clinic */
export const getClinicPublicColRef = (clinicId: string) =>
  getClinicDocRef(clinicId).collection('public');

/* public/clinicData in clinic */
export interface IPublicClinicDataDocument {}

export const getPublicClinicData = (clinicId: string) =>
  getClinicPublicColRef(clinicId).doc('clinicData');

/* public/scenarioData in clinic */
export interface IPublicScenarioDataDocument {
  redirectUrlMap: { [key: string]: string };
}

export const getPublicScenarioDataDoc = (clinicId: string) =>
  getClinicPublicColRef(clinicId).doc('scenarioData');

export const updatePublicScenarioData = async (
  clinicId: string,
  update: Partial<IPublicScenarioDataDocument>,
) => {
  await getPublicScenarioDataDoc(clinicId).update(update);
};

export const fetchPublicScenarioData = async (clinicId: string) => {
  const docSnap = await getPublicScenarioDataDoc(clinicId).get();
  if (!docSnap.exists) return;

  return docSnap.data() as IPublicScenarioDataDocument;
};

/* editable in clinic */
export const getEditableColRef = (clinicId: string) =>
  getClinicDocRef(clinicId).collection('editable');

/* editable/clinicData in clinic */
export interface IClinicLoamData {
  lineUrl: string;
  basicLineAccountId: string;
  premiumLineAccountId: string;
}

export interface IEditableClinicDataDocument extends IClinicLoamData {
  accountName: string;
}

export const getEditableClinicDataDocRef = (clinicId: string) =>
  getEditableColRef(clinicId).doc('clinicData');

export const fetchEditableClinicData = async (clinicId: string) => {
  const docSnap = await getEditableClinicDataDocRef(clinicId).get();
  return docSnap.data() as IEditableClinicDataDocument | undefined;
};

/* editable/scenarioData in clinic */
export interface IScenarioListItem {
  scenarioId: string;
  label: string;
  description: string;
  updatedAt: firebase.firestore.Timestamp;
  createdAt: firebase.firestore.Timestamp;
}

export interface IImage {
  id: string;
  name: string;
  url: string;
}

export interface IEditableScenarioDataDocument {
  variables: IScenarioVariable[];
  scenarioList: IScenarioListItem[];
  scenarioDesign: IScenarioDesign;
  basicInfo: IScenarioBasicInfo;
  images: IImage[];
}

export const getEditableScenarioDataDocRef = (clinicId: string) =>
  getEditableColRef(clinicId).doc('scenarioData');

export const fetchEditableScenarioData = async (
  clinicId: string,
): Promise<IEditableScenarioDataDocument | undefined> => {
  const docSnap = await getEditableScenarioDataDocRef(clinicId).get();
  return docSnap.data() as IEditableScenarioDataDocument | undefined;
};

export const updateEditableScenarioData = async (
  clinicId: string,
  update: Partial<IEditableScenarioDataDocument>,
) => {
  await getEditableScenarioDataDocRef(clinicId).update(update);
};

/* waiting time in clinic */

export interface IWaitingTimeDocument {
  status: string;
  createdAt: firebase.firestore.Timestamp;
}

const RECENT_WAITING_TIMES_LIMIT = 10;

const getWaitingTimesColRef = (clinicId: string) =>
  getClinicDocRef(clinicId).collection('waitingTimes');

// const getWaitingTimeDocRef = (clinicId: string, waitingTimeId: string) =>
// 	getWaitingTimesColRef(clinicId).doc(waitingTimeId);

const getRecentWaitingTimesQuery = (
  clinicId: string,
): firebase.firestore.Query => {
  return getWaitingTimesColRef(clinicId)
    .orderBy('createdAt', 'desc')
    .limit(RECENT_WAITING_TIMES_LIMIT);
};

export const fetchRecentWaitingTimes = async (
  clinicId: string,
): Promise<FirebaseDocument<IWaitingTimeDocument>[]> => {
  const querySnap = await getRecentWaitingTimesQuery(clinicId).get();
  return querySnapToDocArray<IWaitingTimeDocument>(querySnap);
};

export const subscribeRecentWaitingTimes = (
  clinicId: string,
  handler: (clinics: FirebaseDocument<IWaitingTimeDocument>[]) => void,
  errorHandler?: (e: Error) => void,
) => {
  if (!clinicId) throw new Error('clinicId must be provided!');

  return getRecentWaitingTimesQuery(clinicId).onSnapshot(
    (querySnap: firebase.firestore.QuerySnapshot) => {
      const recentWaitingTimes = querySnapToDocArray<IWaitingTimeDocument>(
        querySnap,
      );
      handler(recentWaitingTimes);
    },
    errorHandler,
  );
};

const addWaitingTime = async (
  clinicId: string,
  waitingTime: IWaitingTimeDocument,
): Promise<void> => {
  await getWaitingTimesColRef(clinicId).add(waitingTime);
};

export const updateWaitingTime = async (
  clinicId: string,
  status: string,
): Promise<void> => {
  const waitingTime = {
    status,
    createdAt: getServerTimestamp(),
  };

  await addWaitingTime(clinicId, waitingTime);
};

/* scenarios in clinic */
export interface IBasicScenarioData {
  nodeMap: ScenarioNodeMap;
  keywordsMap: ScenarioKeywordsMap;
}

export interface IScenarioDocumentInClinics extends IBasicScenarioData {
  createdAt: firebase.firestore.Timestamp; //timestamp
  updatedAt: firebase.firestore.Timestamp;
}

export const getScenariosColRef = (
  clinicId: string,
): firebase.firestore.CollectionReference =>
  getClinicDocRef(clinicId).collection('scenarios');

export const getScenarioDocRef = (
  clinicId: string,
  scenarioId: string,
): firebase.firestore.DocumentReference =>
  getScenariosColRef(clinicId).doc(scenarioId);

export const fetchScenarioById = async (
  clinicId: string,
  scenarioId: string,
): Promise<IScenarioDocumentInClinics | undefined> => {
  const docSnap = await getScenarioDocRef(clinicId, scenarioId).get();

  if (!docSnap.exists) return;

  return docSnap.data() as IScenarioDocumentInClinics;
};

export const fetchLatestScenario = async (
  clinicId: string,
): Promise<IScenarioDocumentInClinics | undefined> => {
  const query = getScenariosColRef(clinicId)
    .orderBy('createdAt', 'desc')
    .limit(1);
  const querySnap = await query.get();

  if (querySnap.size === 0) return;

  return querySnap.docs[0].data() as IScenarioDocumentInClinics;
};

export const saveNewScenarioInClinic = async (
  clinicId: string,
  scenario: IBasicScenarioData,
) => {
  const now = getServerTimestamp();
  const scenarioDocument = {
    ...scenario,
    createdAt: now,
    updatedAt: now,
  };
  const docRef = await getScenariosColRef(clinicId).add(scenarioDocument);
  return docRef.id;
};

export const updateScenarioInClinic = async (
  clinicId: string,
  scenarioId: string,
  scenario: IBasicScenarioData,
) => {
  const scenarioDocument = {
    ...scenario,
    updatedAt: getServerTimestamp(),
  };
  await getScenarioDocRef(clinicId, scenarioId).update(scenarioDocument);
};

/* analytics in clinic ***********************/
const getAnalyticsColRef = (clinicId: string) =>
  getClinicDocRef(clinicId).collection('analytics');

/* analytics/events in clinic ****************/

const getEventsDocRef = (clinicId: string) =>
  getAnalyticsColRef(clinicId).doc('events');

export const ClinicEvents = {
  RedirectSuccess: 'redirect_success',
  RedirectFailure: 'redirect_failure',
} as const;

type ClinicEvent = typeof ClinicEvents[keyof typeof ClinicEvents];

const getClinicEventColRef = (clinicId: string, eventName: ClinicEvent) =>
  getEventsDocRef(clinicId).collection(eventName);

const logClinicEvent = <T>(clinicId: string, eventName: ClinicEvent, data: T) =>
  getClinicEventColRef(clinicId, eventName).add({
    ...data,
    createdAt: getServerTimestamp(),
  });

export const logClinicRedirectSuccessEvent = <T>(
  clinicId: string,
  params?: T,
) => logClinicEvent(clinicId, ClinicEvents.RedirectSuccess, params);

export const logClinicRedirectFailureEvent = <T>(
  clinicId: string,
  params?: T,
) => logClinicEvent(clinicId, ClinicEvents.RedirectFailure, params);

/* versions
-------------------------------- */

interface IVersionDocument {
  version: string;
  description: string;
  createdAt: firebase.firestore.Timestamp;
  createdBy: string;
}

const getVersionsColRef = () => getNewBotlogyDocRef().collection('versions');

export const addVersion = async (version: string, description: string) => {
  const currentUserId = getCurrentUserId();
  if (!currentUserId) throw new Error('No Login User!!!');

  const data: IVersionDocument = {
    version,
    description,
    createdAt: getServerTimestamp(),
    createdBy: currentUserId,
  };

  await getVersionsColRef().add(data);
};

/* redirectLogs
-------------------------------- */

interface IRedirectLogDocument {
  accountNumber: string;
  createdAt: firebase.firestore.Timestamp;
  lineUrl: string;
}

const getRedirectLogsColRef = () =>
  getNewBotlogyDocRef().collection('redirectLogs');

// The-Botlogy の document に関しては、docId は accounNumber
const getRedirectLogDocRef = (accountNumber: string) =>
  getRedirectLogsColRef().doc(accountNumber);

export const addRedirectLog = async (accountNumber: string, medium: string) => {
  const logColRef = getRedirectLogDocRef(accountNumber).collection(medium);

  await logColRef.add({ createdAt: getServerTimestamp() });
};

export const fetchLineUrl = async (accountNumber: string) => {
  const docSnap = await getRedirectLogDocRef(accountNumber).get();
  if (!docSnap.exists) return;

  const { lineUrl } = docSnap.data() as IRedirectLogDocument;
  return lineUrl;
};

/* color templates
-------------------------------- */

export interface IColorTemplateDocument {
  scenarioDesign: IScenarioDesign;
}

const getColorTemplatesColRef = () =>
  getNewBotlogyDocRef().collection('colorTemplates');

export const fetchAllColorTemplates = async (): Promise<FirebaseDocument<
  IColorTemplateDocument
>[]> => {
  const querySnap = await getColorTemplatesColRef().get();
  return querySnapToDocArray<IColorTemplateDocument>(querySnap);
};

/* ===================================================
	FUNCTIONS RELATED
====================================================== */

const functions = firebase.functions();

/* applicant 登録  
--------------------------------------------------------*/
export const registerApplicantAccount = async () => {
  if (!getCurrentUserId()) throw new Error('User Not Signed In!');
  await functions.httpsCallable('callable-registerApplicantAccount')();
};

/* applicant 作成 (admin)  
--------------------------------------------------------*/

export type CreateApplicantAccountData = {
  applicant: IApplicantDocument;
  password: string;
};

export const createApplicantAccount = async (
  data: CreateApplicantAccountData,
) => {
  if (!getCurrentUserId()) throw new Error('User Not Signed In!');
  await functions.httpsCallable('callable-createApplicantAccount')(data);
};

/* シナリオ適用  
--------------------------------------------------------*/

interface IDeployScenarioData {
  clinicId: string;
  scenarioId: string;
  nodeMap: ScenarioNodeMap;
  searchKeywords: SearchKeywords;
}

export const deployScenario = async (data: IDeployScenarioData) => {
  await functions.httpsCallable('callable-deployScenario')(data);
};

/* 変数適用  
--------------------------------------------------------*/

interface IDeployScenarioVariablesData {
  clinicId: string;
  nodeMap: ScenarioNodeMap;
}

export const deployScenarioVariables = async (
  data: IDeployScenarioVariablesData,
) => {
  await functions.httpsCallable('callable-deployScenarioVariables')(data);
};

/* 基本情報適用  
--------------------------------------------------------*/

export interface IDeployScenarioBasicInfoData {
  clinicId: string;
  accessInfo: IAccessInfoDocument;
  greetingScenarioNode: IGreetingScenarioNode;
  scenarioIds: string[]; // clinics/{clinicId}/scenarios/{scenarioId} 内の greetingScenarioNode を更新
}

export const deployScenarioBasicInfo = async (
  data: IDeployScenarioBasicInfoData,
) => {
  await functions.httpsCallable('callable-deployScenarioBasicInfo')(data);
};

/* シナリオデザイン適用  
--------------------------------------------------------*/

export interface IDeployScenarioDesignData {
  clinicId: string;
  scenarioDesign: IScenarioDesign;
}

export const deployScenarioDesign = async (data: IDeployScenarioDesignData) => {
  await functions.httpsCallable('callable-deployScenarioDesign')(data);
};

/* シナリオ新規作成  
--------------------------------------------------------*/

export interface ICreateScenarioResponse
  extends firebase.functions.HttpsCallableResult {
  lineUrl: string;
  accountNumber: string;
  basicLineAccountId: string;
}

export const createScenario = async (
  data: ICreateScenarioData,
): Promise<ICreateScenarioResponse> => {
  const res = await functions.httpsCallable('callable-createScenario')(data);
  return res.data as ICreateScenarioResponse;
};

/* [stripe] clientSecret を取得  
--------------------------------------------------------*/

export const fetchClientSecret = async (): Promise<string> => {
  const { data } = await functions.httpsCallable(
    'callable-createSetupIntent',
  )();
  return data.client_secret as string;
};

/* [stripe] credit-card 情報取得  
--------------------------------------------------------*/

export interface IFetchMyCreditCardDetailResponse {
  cardHolderName: string;
  expMonth: number;
  expYear: number;
  cardNumberLast4: string;
}

export const fetchMyCreditCardDetail = async () => {
  const { data } = await functions.httpsCallable(
    'callable-fetchMyCreditCardDetail',
  )();
  return data as IFetchMyCreditCardDetailResponse | undefined;
};

/* [stripe] customer 作成  
--------------------------------------------------------*/

export interface ISaveCreditCardData {
  setupIntent: stripe.setupIntents.SetupIntent;
  coupon?: string;
}

export const createCustomerWithIntent = async (data: ISaveCreditCardData) => {
  await functions.httpsCallable('callable-createCustomerWithIntent')(data);
};

/* [stripe] payment method 更新 (= credit card 情報更新) 
--------------------------------------------------------*/

export const attachNewPaymentMethodAsDefault = async (
  data: ISaveCreditCardData,
) => {
  await functions.httpsCallable('callable-attachNewPaymentMethodAsDefault')(
    data,
  );
};

/* [stripe] 契約情報取得　 
--------------------------------------------------------*/

//TODO create interface !
export interface IFetchMyContractDetailResponse {
  subscriptions: {}[];
}
export const fetchMyContractDetail = async () => {
  const { data } = await functions.httpsCallable(
    'callable-fetchMyContractDetail',
  )();
  return data as IFetchMyContractDetailResponse | undefined;
};

/* アカウント無効化 (= 退会) 
--------------------------------------------------------*/

export const disableMyAccount = async () => {
  await functions.httpsCallable('callable-disableMyAccount')();
};
