import { LOG_LEVELS } from '../constants/log.const';

class FatalError extends Error {
  code: string = 'fatal_error';
  level: number;

  constructor(message: string, code?: string) {
    super(message);

    if (Error.captureStackTrace) {
      Error.captureStackTrace(this, FatalError);
    }

    if (code) this.code = code;
    this.level = LOG_LEVELS.FATAL_ERROR;
  }
}

export default FatalError;
