import {
  PASSWORD_MIN_LENGTH,
  CLINIC_NAME_MAX_LENGTH,
  ACCOUNT_NAME_MAX_LENGTH,
  GREETING_MESSAGE_MAX_LENGTH,
  PREMIUM_LIN_ACCOUNT_ID_MAX_LENGTH,
  EXPLANATION_MAX_LENGTH,
  ACCESS_MAX_LENGTH,
  PARKING_OTHER_MAX_LENGTH,
  SCENARIO_LABEL_MAX_LENGTH,
  VARIABLE_LABEL_MAX_LENGTH,
  VARIABLE_KEY_MAX_LENGTH,
  VARIABLE_VALUE_MAX_LENGTH,
  IMAGE_MAX_LENGTH,
} from './config.const';
import { PREMIUM_LINE_ACCOUNT_ID_SUFFIX } from '../redux/scenario/scenario.state';

// common
export const DEFAULT_ERROR_MESSAGE = 'エラーが発生しました';
export const SIGNIN_ERROR_MESSAGE = 'Email または Password が間違っています';
export const NETWORK_ERROR_MESSAGE = 'インターネットに接続されていません';
export const USER_NOT_FOUND_ERROR_MESSAGE = 'アカウントが存在しません';
export const INTERNET_DISCONNECTED_TRY_AGAIN =
  'インターネットの接続をお確かめの上、もう一度お試しください。';
export const UNEXPECTED_ERROR_MESSAGE = '予期せぬエラーが発生しました。';
export const TRY_AGAIN_MESSAGE = 'もう一度お試しください';
export const EMAIL_TAKEN_ERROR_MESSAGE =
  'すでに使用されている Emial アドレスです';
export const FAILED_TO_FETCH_DATA_MESSAGE = 'データの読み込みに失敗しました。';
export const ALREADY_EXISTS_ERROR_MESSAGE = 'この値はすでに存在します。';

//validation
export const INVALID_VALUE_EXIST_MESSAGE = `無効な値が存在します`;
export const INVALID_EMAIL_MESSAGE = '無効な Email アドレスです';
export const INVALID_PASSWORD_MESSAGE = `${PASSWORD_MIN_LENGTH}文字以上のパスワードを入力してください`;
export const INVALID_CLINIC_NAME_MESSAGE = `${CLINIC_NAME_MAX_LENGTH}文字以下のクリニック名を入力してください`;
export const INVALID_ACCOUNT_NAME_MESSAGE = `${ACCOUNT_NAME_MAX_LENGTH}文字以下のアカウント名を入力してください`;
export const INVALID_GREETING_MESSAGE_MESSAGE = `${GREETING_MESSAGE_MAX_LENGTH}文字以下の紹介文を入力してください`;
export const INVALID_PREMIUM_LIN_ACCOUNT_ID_MESSAGE = `${PREMIUM_LIN_ACCOUNT_ID_MAX_LENGTH -
  PREMIUM_LINE_ACCOUNT_ID_SUFFIX.length}文字以下の半角英数字を入力してください`;
export const INVALID_EXPLANATION_MESSAGE = `${EXPLANATION_MAX_LENGTH}文字以下の但し書きを入力してください`;
export const INVALID_PARKING_OTHER_MESSAGE = `${PARKING_OTHER_MAX_LENGTH}文字以下のテキストを入力してください`;
export const INVALID_ACCESS_MESSAGE = `${ACCESS_MAX_LENGTH}文字以下でアクセス情報を入力してください`;
export const INVALID_ACCESS_TOKEN_MESSAGE = `無効なアクセストークンです`;
export const INVALID_LINE_REGISTRATION_URL_MESSAGE = `無効なLINE友達登録URLです`;
export const INVALID_URL_MESSAGE = `無効なURLです`;
export const INVALID_ADRESS_MESSAGE = `無効な住所です`;
export const INVALID_PHONE_NUMBER_MESSAGE = `無効な電話番号です`;
export const INVALID_CHECKED_TEMPLATE_OPTIONS_MESSAGE = `治療メニューを最低１つ選択してください`;
export const INVALID_SELECTED_COLOR_TEMPLATE_MESSAGE = `デザインパターンを選択してください`;
export const INVALID_CARD_HOLDER_NAME_MESSAGE = `無効なカード名義です`;
export const INVALID_CAMPAIGN_CODE_MESSAGE = `無効なキャンペーンコードです`;
export const INVALID_SCENARIO_LABEL_MESSAGE = `${SCENARIO_LABEL_MAX_LENGTH}文字以下のシナリオ名を入力してください`;
export const INVALID_VARIABLE_LABEL_MESSAGE = `${VARIABLE_LABEL_MAX_LENGTH}文字以下の変数名を入力してください`;
export const INVALID_VARIABLE_KEY_MESSAGE = `${VARIABLE_KEY_MAX_LENGTH}文字以下の変数を入力してください`;
export const INVALID_VARIABLE_VALUE_MESSAGE = `${VARIABLE_VALUE_MAX_LENGTH}文字以下の値を入力してください`;
export const INVALID_IMAGE_NAME_MESSAGE = `${IMAGE_MAX_LENGTH}文字以下の値を入力してください`;

// account data
export const FAILED_TO_FETCH_ACCOUNT_DATA_MESSAGE =
  'ユーザーデータの読み込みに失敗しました。';

// applicant data
export const FAILED_TO_FETCH_APPLICANT_DATA_MESSAGE =
  'ユーザーデータの読み込みに失敗しました。';

// clinic data
export const FAILED_TO_FETCH_CLINIC_DATA_MESSAGE =
  'クリニックデータの読み込みに失敗しました。';

// clinic list data
export const FAILED_TO_FETCH_CLINIC_LIST_DATA_MESSAGE =
  'クリニック一覧データの読み込みに失敗しました。';

// editable data
export const FAILED_TO_FETCH_EDITABLE_CLINIC_DATA_MESSAGE =
  'マイページデータの読み込みに失敗しました。';

// editable scenario data
export const FAILED_TO_FETCH_EDITABLE_SCENARIO_DATA_MESSAGE =
  'シナリオデータの読み込みに失敗しました。';

// my credit card detail
export const FAILED_TO_FETCH_MY_CREDIT_CARD_DETAIL_MESSAGE =
  'クレジットカード情報の取得に失敗しました。';

// waitingTimes data
export const FAILED_TO_FETCH_WAITING_TIMES_DATA_MESSAGE =
  '混雑状況データの読み込みに失敗しました。';

// scenario list ittems
export const FAILED_TO_FETCH_SCENARIO_LIST_ITEMS_DATA_MESSAGE =
  'シナリオリストデータの読み込みに失敗しました。';

// scenario data
export const FAILED_TO_FETCH_SCENARIO_DATA_MESSAGE =
  'シナリオデータの読み込みに失敗しました。';

// scenarioDesign data
export const FAILED_TO_FETCH_SCENARIO_DESIGN_DATA_MESSAGE =
  'シナリオデザインデータの読み込みに失敗しました。';

// variables data
export const FAILED_TO_FETCH_VARIABLES_DATA_MESSAGE =
  '変数データの読み込みに失敗しました。';

// color templates
export const FAILED_TO_FETCH_COLOR_TEMPLATES_MESSAGE =
  'カラーテンプレートの読み込みに失敗しました。';

// status update
export const STATUS_UPDATE_ERROR_MESSAGE = 'メッセージの更新に失敗しました。';

// password reset
export const CHECK_EMAIL_ERROR_MESSAGE = 'Email アドレスが間違っています。';

// invalid url
export const INVALID_SEARCH_PARAMS_MESSAGE = '無効なURLです';

// email auth
export const EMAIL_AUTH_FAILURE_MESSAGE = 'Email 認証に失敗しました';

//strip
export const CARD_DECLINE_ERROR_MESSAGE = '無効なカードです。';
