import actionCreatorFactory from 'typescript-fsa';
import { IApplicantState, ILoamState } from './applicant.state';

const actionCreator = actionCreatorFactory();

export default {
  setApplicant: actionCreator<IApplicantState>('SET_APPLICANT'),

  setAccountNumber: actionCreator<string>('SET_ACCOUNT_NUMBER'),

  setLoam: actionCreator<ILoamState>('SET_LOAM'),
};
