import referrersData from './referrers.json';

/***********************************************
 * 紹介元のデータは、紹介元の account を生成したのち、
 * その情報を referrers.json に記載する。
 *
 *  => referrers.json  の中身は IReferrer[]
 *
 * exp)
 *      [{
 *        "accountId": "dummy_white_cross_account_id",
 *        "name": "WHITE_CROSS",
 *        "label": "ホワイトクロス"
 *      }, {
 *        "accountId": "dummy_botlogy_account_id",
 *        "name": "botlogy",
 *        "label": "株式会社Botlogy"
 *      }]
 *
 ***********************************************/

export interface IReferrer {
  accountId: string;
  name: string;
  label: string;
}

const SYSTEM_REFERRER: IReferrer = {
  accountId: 'system',
  name: 'system',
  label: 'なし',
};

export interface IReferrerState {
  referrers: IReferrer[];
}

export const INITIAL_REFERRER_STATE: IReferrerState = {
  referrers: [SYSTEM_REFERRER, ...referrersData],
};
