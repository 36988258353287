import { reducerWithInitialState } from 'typescript-fsa-reducers';
import actions from './applicant.actions';
import {
  INITIAL_APPLICANT_STATE,
  IApplicantState,
  ILoamState,
} from './applicant.state';
import { IApplicantDocument } from '../../utils/firebase.util';

/* ===================================================
	HANDLER
====================================================== */

interface IActionHandler<T = void> {
  (state: IApplicantState, paylod: T): IApplicantState;
}

const handleSetApplicant: IActionHandler<IApplicantDocument> = (
  state,
  applicant,
) => {
  return {
    ...state,
    ...applicant,
  };
};

const handleSetAccountNumber: IActionHandler<string> = (
  state,
  accountNumber,
) => {
  return {
    ...state,
    accountNumber,
  };
};

const handleSetLoam: IActionHandler<ILoamState> = (state, loam) => {
  return {
    ...state,
    ...loam,
  };
};

/* ===================================================
	REDUCER
====================================================== */

const scenarioReducer = reducerWithInitialState(INITIAL_APPLICANT_STATE)
  /* applicant document */
  .case(actions.setApplicant, handleSetApplicant)

  .case(actions.setAccountNumber, handleSetAccountNumber)

  /* loam */
  .case(actions.setLoam, handleSetLoam);

export default scenarioReducer;
