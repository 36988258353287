import React from 'react';

import style from './error-screen.module.scss';
import {
  TRY_AGAIN_MESSAGE,
  UNEXPECTED_ERROR_MESSAGE,
} from '../../constants/error-message.const';

export const ErrorIcon = () => {
  return (
    <svg
      className={style.icon}
      width="540"
      height="540"
      viewBox="0 0 540 540"
      fill="none"
      xmlns="http://www.w3.org/2000/svg"
    >
      <path
        d="M286.021 42.75L474.815 369.75C481.936 382.083 473.035 397.5 458.794 397.5H81.2065C66.9652 397.5 58.0643 382.083 65.185 369.75L253.979 42.75C261.099 30.4167 278.901 30.4167 286.021 42.75Z"
        stroke="#5B5B5B"
        strokeWidth="15"
      />
      <path
        d="M270 177C277.843 177 283.603 178.417 287.279 181.251C290.956 183.909 292.794 188.249 292.794 194.271C292.794 200.826 291.201 215.174 288.015 237.317C284.828 259.283 281.275 282.754 277.353 307.731H262.647C258.725 282.754 255.172 259.283 251.985 237.317C248.799 215.174 247.206 200.826 247.206 194.271C247.206 188.249 249.044 183.909 252.721 181.251C256.397 178.417 262.157 177 270 177ZM270 326.863C277.843 326.863 283.971 328.457 288.382 331.646C292.794 334.657 295 339.086 295 344.931C295 350.777 292.794 355.294 288.382 358.483C283.971 361.494 277.843 363 270 363C262.157 363 256.029 361.494 251.618 358.483C247.206 355.294 245 350.777 245 344.931C245 339.086 247.206 334.657 251.618 331.646C256.029 328.457 262.157 326.863 270 326.863Z"
        fill="#5B5B5B"
      />
    </svg>
  );
};

interface IErrorScreen {
  isFatal?: boolean;
  errorMessages?: string[];
}

const ErrorScreen: React.FC<IErrorScreen> = ({
  isFatal = false,
  errorMessages = [UNEXPECTED_ERROR_MESSAGE, TRY_AGAIN_MESSAGE],
}) => {
  return (
    <div className={style.container}>
      <ErrorIcon />
      {isFatal && <h1 className={style.message}>Something went worng ...</h1>}
      <div className={style.descriptionBox}>
        {errorMessages.map(errorMessage => (
          <p key={errorMessage} className={style.description}>
            {errorMessage}
          </p>
        ))}
      </div>
    </div>
  );
};

export default ErrorScreen;
