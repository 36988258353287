import firebaseConfig from '../config/firebase.config';
import firebase from 'firebase/app';
import 'firebase/auth';
import 'firebase/firestore';
import 'firebase/storage';
import 'firebase/functions';
import 'firebase/analytics';

/**MEMO
 * test で auth/firestore を mock するために initの処理を関数化
 */

/* ===================================================
	INITIALIZATION
====================================================== */
type App = firebase.app.App;
type Auth = firebase.auth.Auth;
type Firestore = firebase.firestore.Firestore;
type Storage = firebase.storage.Storage;
type Analytics = firebase.analytics.Analytics;

let app;
export let auth: Auth;
export let firestore: Firestore;
export let storage: Storage;
export let analytics: Analytics;

interface IMockConfig {
  auth: {};
  firestore: () => void;
}

interface IInitFirebaseApp {
  app: App;
  auth: Auth;
  firestore: Firestore;
  storage: Storage;
  analytics: Analytics;
}

// dependency injection >>>> mockConfig: { auth: object, firestore: fn}
// return values are to be used in test
export const initFirebaseApp = (mockConfig?: IMockConfig): IInitFirebaseApp => {
  if (!firebaseConfig) throw new Error('firebase config must be set!!!');

  app = firebase.initializeApp(mockConfig || firebaseConfig);
  auth = app.auth();
  firestore = app.firestore();
  storage = app.storage();
  analytics = app.analytics();
  return { app, auth, firestore, storage, analytics };
};

initFirebaseApp();
