export const theme = {
  fontSize: {
    xLarge: '3rem',
    large: '2rem',
    default: '1.6rem',
    medium: '1.4rem',
    small: '1.2rem',
    xSmall: '1rem',
  },
  color: {
    greyLight1: '#faf9f9',
    greyLight2: '#f4f2f2',
    greyLight3: '#f0eeee',
    greyLight4: '#ccc7',
    greyDark1: '#333',
    greyDark2: 'rgb(103, 103, 103)',
    greyDark3: '#999',

    primary: '#3f51b5',
    secondary: 'rgb(220, 0, 78)',
    tertiary: '#66bb6a',

    primaryLight1: '#f8f9fb',
    primaryLight2: '#a9b0da',
    primaryLight3: '#727fca',
    primaryDark3: '#1d3099',

    secondaryLight1: 'rgb(252, 241, 245)',
    secondaryLight2: 'rgb(245, 124, 166)',
    secondaryLight3: 'rgb(235, 53, 117)',

    tertiaryDark2: '#00614a',
    tertiaryDark3: '#008466',
  },
};
