import { combineReducers } from 'redux';
import { persistReducer } from 'redux-persist';
import storageSession from 'redux-persist/lib/storage/session';

import scenarioReducer from './scenario/scenario.reducer';
import applicantReducer from './applicant/applicant.reducer';
import referrerReducer from './referrer/referrer.reducer';
import { IScenarioState } from './scenario/scenario.state';
import { IApplicantState } from './applicant/applicant.state';
import { IReferrerState } from './referrer/referrer.state';

export interface IAppState {
  scenario: IScenarioState;
  applicant: IApplicantState;
  referrer: IReferrerState;
}

const persistConfig = {
  key: 'root',
  storage: storageSession,
  whitelist: ['scenario', 'applicant'],
};

const rootReducer = combineReducers<IAppState>({
  scenario: scenarioReducer,
  applicant: applicantReducer,
  referrer: referrerReducer,
});

export default persistReducer(persistConfig, rootReducer);
