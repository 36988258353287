export const QR_REDIRECT_PATH = '/qrRedirect';
export const REDIRECT_ROUTES_PATH = '/redirect';

/* visitor routes */
export const SIGN_IN_PATH = '/';
export const SIGN_UP_PATH = '/signUp';
export const EMAIL_LINK_SIGN_UP_PATH = '/emailLinkSignUp';
export const EMAIL_LINK_SIGN_IN_PATH = '/emailLinkSignIn';

/* account routes */
export const HOME_PATH = '/';
export const WAITING_TIME_PATH = '/waitingTime';
export const SCENARIO_MANAGEMENT_PATH = '/scenarioManagement';
export const SCENARIO_EDITOR_PATH = '/scenarioEditor';

/* admin routes */
export const CLINICS_PATH = '/clinics';
export const CLINIC_LIST_PATH = '/clinicList';
export const CREATE_APPLICNAT_PATH = '/createApplicant';
