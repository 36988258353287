import { library } from '@fortawesome/fontawesome-svg-core';
import { fab } from '@fortawesome/free-brands-svg-icons';
import {
  faCheckSquare,
  faChevronRight,
  faChevronDown,
  faPlusSquare,
  faMinusSquare,
  faFolder,
  faFolderOpen,
  faFile,
  faSquare,
  faHospitalAlt,
  faUserPlus,
  faAngleRight,
  faAngleLeft,
  faClipboardCheck,
  faSadTear,
  faAngleDoubleRight,
} from '@fortawesome/free-solid-svg-icons';

library.add(
  fab,
  faCheckSquare,
  faChevronRight,
  faChevronDown,
  faPlusSquare,
  faMinusSquare,
  faFolder,
  faFolderOpen,
  faFile,
  faSquare,
  faHospitalAlt,
  faUserPlus,
  faAngleRight,
  faAngleLeft,
  faClipboardCheck,
  faSadTear,
  faAngleDoubleRight,
);
