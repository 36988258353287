import {
  NETWORK_ERROR_MESSAGE,
  SIGNIN_ERROR_MESSAGE,
  EMAIL_TAKEN_ERROR_MESSAGE,
  // USER_NOT_FOUND_ERROR_MESSAGE
} from './error-message.const';

export interface IFirebaseError extends Error, firebase.auth.Error {}

export const FirebaseErrorCodes = {
  AUTH: {
    USER_NOT_FOUNT: 'auth/user-not-found',
    WROGN_PASSWORD: 'auth/wrong-password',
    NETWORK_REQUEST_FAILED: 'auth/network-request-failed',
    EAMIO_TAKEN: 'auth/email-already-in-use',
    USER_DISABLED: 'auth/user-disabled',
    TOO_MANY_REQUEST: 'auth/too-many-requests',
  },
};

// show SIGNIN_ERROR_MESSAGE istead of USER_NOT_FOUND_ERROR_MESSAGE if user account does not exist
const firebaseErrorMessages = {
  [FirebaseErrorCodes.AUTH.NETWORK_REQUEST_FAILED]: NETWORK_ERROR_MESSAGE,
  [FirebaseErrorCodes.AUTH.WROGN_PASSWORD]: SIGNIN_ERROR_MESSAGE,
  // 'auth/user-not-found': USER_NOT_FOUND_ERROR_MESSAGE
  [FirebaseErrorCodes.AUTH.USER_NOT_FOUNT]: SIGNIN_ERROR_MESSAGE,
  [FirebaseErrorCodes.AUTH.EAMIO_TAKEN]: EMAIL_TAKEN_ERROR_MESSAGE,
};

export default firebaseErrorMessages;
