import actionCreatorFactory from 'typescript-fsa';
import {
  ITimeTableRow,
  ParkingValue,
  CreateScenarioStep,
  HasAppointmentUrlValue,
  IScenarioBasicInfo,
  IScenarioDesign,
} from './scenario.state';
import {
  FirebaseDocument,
  IColorTemplateDocument,
  IImage,
} from '../../utils/firebase.util';

const actionCreator = actionCreatorFactory();

export interface IUpdateImageNamePayload {
  id: string;
  name: string;
}

export default {
  /*===========================================
   * SCENARIO TEMPLATE DATE (from firestore)
   *===========================================*/

  // setScenarioTemplateState: actionCreator<IScenarioTemplateState>(
  //   'SET_SCENARIO_TEMPLATE_STATE',
  // ),

  // setScenarioTemplate: actionCreator<{
  //   scenarioTemplate: Template;
  //   scenarioTemplateTopId: string;
  // }>('SET_SCENARIO_TEMPLATE'),

  // setScenarioTemplateOptions: actionCreator<CheckBoxNode[]>(
  //   'SET_SCENARIO_TEMPLATE_OPTIONS',
  // ),

  /*===========================================
   * SCENARIO FORM (OVERALL)
   *===========================================*/

  setCreateScenarioStep: actionCreator<CreateScenarioStep>(
    'SET_CREATE_SCENARIO_STEP',
  ),

  /*===========================================
   * SCENARIO CLINIC FORM (STEP1)
   *===========================================*/

  /* greetingMessage */
  setGreetingMessage: actionCreator<string>('SET_GREETING_MESSAGE'),

  /* premiumLineAccountId */
  setPremiumLineAccountId: actionCreator<string>('SET_PREMIUM_LINE_ACCOUNT_ID'),

  /* website */
  setWebsiteUrl: actionCreator<string>('SET_WEBSITE_URL'),

  /* appointmentUrl */
  setHasAppointmentUrl: actionCreator<HasAppointmentUrlValue>(
    'SET_HAS_APPOINTMENT_URL',
  ),

  setAppointmentUrl: actionCreator<string>('SET_APPOINTMENT_URL'),

  /* parking */
  setParking: actionCreator<ParkingValue>('SET_PARKING'),

  setParkingOther: actionCreator<string>('SET_PARKING_OHTER'),

  /* accesses */
  setAccessWithIndex: actionCreator<{ index: number; value: string }>(
    'SET_ACCESSES_WITH_INDEX',
  ),

  addAccess: actionCreator<void>('ADD_ACCESSES'),

  removeAccess: actionCreator<number>('REMOVE_ACCESSES'),

  /* timeTable */
  addTimeTableRow: actionCreator<void>('ADD_TIME_TABLE_ROW'),

  removeTimeTableRow: actionCreator<number>('REMOVE_TIME_TABLE_ROW'),

  updateTimeTableRow: actionCreator<{ rowIndex: number; row: ITimeTableRow }>(
    'UPDATE_TIME_TABLE_ROW',
  ),

  toggleOpeningStatus: actionCreator<{ rowIndex: number; dayIndex: number }>(
    'TOGGLE_OPENING_STATUS',
  ),

  setOpeningTime: actionCreator<{ rowIndex: number; time: string }>(
    'SET_OPENING_TIME',
  ),

  setClosingTime: actionCreator<{ rowIndex: number; time: string }>(
    'SET_CLOSING_TIME',
  ),

  setTimeTableExplanation: actionCreator<string>('SET_TIME_TABLE_EXPLANATION'),

  /* scenario clinic form */
  validateScenarioClinicForm: actionCreator<void>(
    'VALIDATE_SCENARIO_CLINIC_FORM',
  ),

  setIsScenarioClinicFormComplete: actionCreator<boolean>(
    'SET_IS_SCENARIO_CLINIC_FORM_COMPLETE',
  ),

  /*===========================================
   * SCENARIO TEMPLATE FORM (STEP2)
   *===========================================*/

  /* clinicName */
  setClinicName: actionCreator<string>('SET_CLINIC_NAME'),

  /* phoneNumber */
  setPhoneNumber: actionCreator<string>('SET_PHONE_NUMBER'),

  /* colorTemplate */
  setColorTemmplate: actionCreator<FirebaseDocument<IColorTemplateDocument>>(
    'SET_COLOR_TEMPLATE',
  ),

  /* templateOptions */
  setCheckedTemplateOptions: actionCreator<string[]>(
    'SET_CHECKED_TEMPLATE_OPTIONS',
  ),

  /* scenario template form*/
  validateScenarioTemplateForm: actionCreator<void>(
    'VALIDATE_SCENARIO_TEMPLATE_FORM',
  ),

  setIsScenarioTemplateFormComplete: actionCreator<boolean>(
    'SET_IS_SCENARIO_TEMPLATE_FORM_COMPLETE',
  ),

  /*================================================
   * SCENARIO BASIC INFO  (scenario-management-page)
   *===============================================*/

  setScenarioBasicInfo: actionCreator<IScenarioBasicInfo>(
    'SET_SCENARIO_BASIC_INFO',
  ),

  /*================================================
   * SCENARIO DESIGN (scenario-management-page)
   *===============================================*/

  setScenarioDesign: actionCreator<IScenarioDesign>('SET_SCENARIO_DESIGN'),

  /*================================================
   * IMAGES (scenario-management-page)
   *===============================================*/

  setImages: actionCreator<IImage[]>('SET_IMAGES'),

  updateImageName: actionCreator<IUpdateImageNamePayload>('UPDATE_IMAGE_NAME'),
};
