import 'react-app-polyfill/ie9';
import 'react-app-polyfill/stable';
// TextEncoder polyfill
import 'fast-text-encoding/text';

import React from 'react';
import ReactDOM from 'react-dom';
import { Provider as ReduxProvider } from 'react-redux';
import { PersistGate } from 'redux-persist/integration/react';
import { StripeProvider } from 'react-stripe-elements';
import { STRIPE_API_KEY } from './config/stripe.config';
import './styles/main.scss';
import 'typeface-roboto';

import { StylesProvider } from '@material-ui/core/styles';
import { BotlogyThemeProvider } from './styles/theme-provider';
import './startup/firebase.startup';
import './startup/fontawesome.startup';

import App from './app.component';
import * as serviceWorker from './serviceWorker';

import store, { persistor } from './redux/store';

ReactDOM.render(
  <StylesProvider injectFirst>
    <BotlogyThemeProvider>
      <ReduxProvider store={store}>
        <PersistGate persistor={persistor}>
          <StripeProvider apiKey={STRIPE_API_KEY}>
            <App />
          </StripeProvider>
        </PersistGate>
      </ReduxProvider>
    </BotlogyThemeProvider>
  </StylesProvider>,
  document.getElementById('root'),
);

// If you want your app to work offline and load faster, you can change
// unregister() to register() below. Note this comes with some pitfalls.
// Learn more about service workers: https://bit.ly/CRA-PWA
serviceWorker.unregister();
