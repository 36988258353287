import {
  FirebaseDocument,
  IColorTemplateDocument,
  IImage,
} from '../../utils/firebase.util';

import {
  IScenarioVariable,
  ScenarioNodeMap,
  SearchKeywords,
  ScenarioKeywordsMap,
} from '@botlogy/scenario-editor/build/redux/scenario/scenario.state';

/*===========================================
 * SCENARIO TEMPLATE DATE (from firestore)
 *==========================================*/

// export interface IScenarioTemplateState {
//   scenarioTemplate?: Template;
//   scenarioTemplateTopId?: string;
//   scenarioTemplateOptions?: CheckBoxNode[];
// }

// export const INITIAL_SCENARIO_TEMPLATE_STATE: IScenarioTemplateState = {
//   scenarioTemplate: undefined,
//   scenarioTemplateTopId: undefined,
//   scenarioTemplateOptions: undefined,
// };

/*===========================================
 * CREATE SCENARIO PAGE
 *===========================================*/

/* create scenario page base
 -------------------------*/

export enum CreateScenarioStep {
  Clinic,
  Template,
  Success,
}

export interface ICreateScenarioBaseState {
  step: CreateScenarioStep;
}

export const INITIAL_CREATE_SCENARIO_BASE_STATE: ICreateScenarioBaseState = {
  step: CreateScenarioStep.Clinic,
};

/*===========================================
 * SCENARIO CLINIC FORM (STEP1)
 *===========================================*/

/* timeTable 
------------------------- */

export const DAYS = ['日', '月', '火', '水', '木', '金', '土'];

export enum TimeKeyName {
  OpeningTime = 'openingTime',
  ClosingTime = 'closingTime',
}

export interface ITimes {
  [TimeKeyName.OpeningTime]: string;
  [TimeKeyName.ClosingTime]: string;
}

export enum OpeningStatus {
  Open = '○',
  Close = '×',
}

export interface ITimeTableRow {
  times: ITimes;
  days: OpeningStatus[];
}

export const initialTimeTableRow: ITimeTableRow = {
  times: {
    openingTime: '00:00',
    closingTime: '00:00',
  },
  days: new Array(DAYS.length).fill(OpeningStatus.Open),
};

export interface ITimeTableState {
  timeTableRows: ITimeTableRow[];
  timeTableExplanation: string;
  timeTableExplanationHelperText: string;
}

export const INITIAL_TIME_TABLE_STATE: ITimeTableState = {
  timeTableRows: [{ ...initialTimeTableRow }],
  timeTableExplanation: '',
  timeTableExplanationHelperText: '',
};

/* accesses
---------------------------*/
export interface IAccessState {
  value: string;
  helperText: string;
}

export const initialAccess = { value: '', helperText: '' };

export interface IAccessesState {
  accesses: IAccessState[];
}

export const INITIAL_ACCESSED_STATE: IAccessesState = {
  accesses: [{ ...initialAccess }],
};

/* greetingMessage
---------------------------*/
export interface IGreetingMessageState {
  greetingMessage: string;
  greetingMessageHelperText: string;
}

export const INITIAL_GREETING_MESSAGE_STATE: IGreetingMessageState = {
  greetingMessage: '',
  greetingMessageHelperText: '',
};

/* premiumAccountId
---------------------------*/
export const PREMIUM_LINE_ACCOUNT_ID_SUFFIX = '_ux';

export interface IPremiumLineAccountIdState {
  premiumLineAccountId: string;
  premiumLineAccountIdHelperText: string;
}

export const INITIAL_PREMIUM_LINE_ACCOUNT_ID_STATE: IPremiumLineAccountIdState = {
  premiumLineAccountId: '',
  premiumLineAccountIdHelperText: '',
};

/* websiteUrlState
---------------------------*/
export interface IWebsiteUrlState {
  websiteUrl: string;
  websiteUrlHelperText: string;
}

export const INITIAL_WEBSITE_URL_STATE: IWebsiteUrlState = {
  websiteUrl: '',
  websiteUrlHelperText: '',
};

/* appointmentUrl
---------------------------*/
export enum HasAppointmentUrlValue {
  On = 'あり',
  Off = 'なし',
}
export interface IAppointmentUrlState {
  hasAppointmentUrl: HasAppointmentUrlValue;
  appointmentUrl: string;
  appointmentUrlHelperText: string;
}

export const INITIAL_APPOINTMENT_URL_STATE: IAppointmentUrlState = {
  hasAppointmentUrl: HasAppointmentUrlValue.On,
  appointmentUrl: '',
  appointmentUrlHelperText: '',
};

/* parking
---------------------------*/
export enum ParkingValue {
  On = 'あり',
  Off = 'なし',
  Other = 'その他',
}

export interface IParkingState {
  parking: ParkingValue;
  parkingOther: string;
  parkingOtherHelperText: string;
}

export const INITIAL_PARKING_STATE: IParkingState = {
  parking: ParkingValue.On,
  parkingOther: '',
  parkingOtherHelperText: '',
};

/* scenario clinic form
---------------------------*/
export interface IScenarioClinicFormState
  extends IGreetingMessageState,
    IPremiumLineAccountIdState,
    IWebsiteUrlState,
    IAppointmentUrlState,
    IParkingState,
    IAccessesState,
    ITimeTableState {
  isScenarioClinicFormComplete: boolean;
  scenarioClinicFormInvalidMessage: string;
}

export const INITIAL_SCENARIO_CLINIC_FORM_STATE = {
  ...INITIAL_GREETING_MESSAGE_STATE,
  ...INITIAL_PREMIUM_LINE_ACCOUNT_ID_STATE,
  ...INITIAL_WEBSITE_URL_STATE,
  ...INITIAL_APPOINTMENT_URL_STATE,
  ...INITIAL_PARKING_STATE,
  ...INITIAL_ACCESSED_STATE,
  ...INITIAL_TIME_TABLE_STATE,
  isScenarioClinicFormComplete: false,
  scenarioClinicFormInvalidMessage: '',
};

/* basicInfo for editable scenarioData
---------------------------------------*/
export interface IScenarioBasicInfo {
  websiteUrl: string;
  hasAppointmentUrl: HasAppointmentUrlValue;
  appointmentUrl: string;
  greetingMessage: string;
  parking: ParkingValue;
  parkingOther: string;
  accesses: IAccessState[];
  timeTableRows: ITimeTableRow[];
  timeTableExplanation: string;
}

/*===========================================
 * SCENARIO TEMPLATE FORM (STEP2)
 *===========================================*/

/* clinicName
---------------------------*/

export interface IClinicNameState {
  clinicName: string;
  clinicNameHelperText: string;
}

export const INITIAL_CLINIC_NAME_STATE: IClinicNameState = {
  clinicName: '',
  clinicNameHelperText: '',
};

/* phoneNumber
---------------------------*/

export interface IPhoneNumberState {
  phoneNumber: string;
  phoneNumberHelperText: string;
}

export const INITIAL_PHONE_NUMBER_STATE: IPhoneNumberState = {
  phoneNumber: '',
  phoneNumberHelperText: '',
};

/* color template
---------------------------*/

export interface IColorTemplateState {
  selectedColorTemplate?: FirebaseDocument<IColorTemplateDocument>;
  selectedColorTemplateHelperText: string;
}

export const INITIAL_COLOR_TEMPLATE_STATE: IColorTemplateState = {
  selectedColorTemplate: undefined,
  selectedColorTemplateHelperText: '',
};

/* template options
---------------------------*/

export interface ICheckedTemplateOptionsState {
  checkedTemplateOptions?: string[];
  checkedTemplateOptionsHelperText: string;
}

export const INITIAL_CHECKED_TEMPLATE_OPTIONS_STATE: ICheckedTemplateOptionsState = {
  checkedTemplateOptions: undefined,
  checkedTemplateOptionsHelperText: '',
};

/* scenario template form
---------------------------*/
export interface IScenarioTemplateFormState
  extends IClinicNameState,
    IPhoneNumberState,
    IColorTemplateState,
    ICheckedTemplateOptionsState {
  isScenarioTemplateFormComplete: boolean;
  scenarioTemplateFormInvalidMessage: string;
}

export const INITIAL_SCENARIO_TEMPLATE_FORM_STATE: IScenarioTemplateFormState = {
  ...INITIAL_CLINIC_NAME_STATE,
  ...INITIAL_PHONE_NUMBER_STATE,
  ...INITIAL_COLOR_TEMPLATE_STATE,
  ...INITIAL_CHECKED_TEMPLATE_OPTIONS_STATE,
  isScenarioTemplateFormComplete: false,
  scenarioTemplateFormInvalidMessage: '',
};

/*===========================================
 * SCENARIO MANAGEMENT PAGE
 *==========================================*/

export interface IScenarioDesign {
  backgroundColor: string;
  greetingScenarioNode: {
    bodyBackColor: string;
    bodyButtonColor: string;
    bodyTextColor: string;
    footerBackColor: string;
  };
  imageExplanationScenarioNode: {
    bodyBackColor: string;
    bodyTextColor: string;
  };
  textScenarioNode: {
    bodyBackColor: string;
    bodyTextColor: string;
    headerBackColor: string;
    headerTextColor: string;
    initButtonColor: string;
    preButtonColor: string;
  };
  richMenuColor: string;
}

const INITIAL_SCENARIO_DESIGN: IScenarioDesign = {
  backgroundColor: '#158ACD',
  greetingScenarioNode: {
    bodyBackColor: '#74acdf',
    bodyButtonColor: '#5091CB',
    bodyTextColor: '#FFFFFF',
    footerBackColor: '#FFFFFF',
  },
  imageExplanationScenarioNode: {
    bodyBackColor: '#FFFFFF',
    bodyTextColor: '#000000',
  },
  richMenuColor: '#74ACE0',
  textScenarioNode: {
    bodyBackColor: '#FFFFFF',
    bodyTextColor: '#000000',
    headerBackColor: '#74acdf',
    headerTextColor: '#FFFFFF',
    initButtonColor: '#74acdf',
    preButtonColor: '#74acdf',
  },
};

export interface IScenarioDesignState {
  scenarioDesign: IScenarioDesign;
}

export const INITIAL_SCENARIO_DESIGN_STATE: IScenarioDesignState = {
  scenarioDesign: INITIAL_SCENARIO_DESIGN,
};

export interface IImagesState {
  images: IImage[];
}

export const INITIAL_IMAGES_STATE: IImagesState = {
  images: [],
};

/*===========================================
 * SCENARIO
 *===========================================*/

/* scenario state
---------------------------*/
//TODO add state of step2
export interface IScenarioState  // IScenarioTemplateState,
  extends ICreateScenarioBaseState,
    IScenarioClinicFormState,
    IScenarioTemplateFormState,
    IScenarioDesignState,
    IImagesState {}

export const INITIAL_SCENARIO_STATE: IScenarioState = {
  ...INITIAL_CREATE_SCENARIO_BASE_STATE,
  ...INITIAL_SCENARIO_CLINIC_FORM_STATE,
  ...INITIAL_SCENARIO_TEMPLATE_FORM_STATE,
  // ...INITIAL_SCENARIO_TEMPLATE_STATE,
  ...INITIAL_SCENARIO_DESIGN_STATE,
  ...INITIAL_IMAGES_STATE,
};

/*===========================================
 * CREATE SCENARIO DATA
 *==========================================*/

/* clinicData */

export interface IClinicData {
  referrerId: string;
  hospitalName: string;
  doctorName: string;
}

/* editable clinicData */

export interface IEditableClinicData {
  accountName: string;
  premiumLineAccountId: string;
}

/* sceanrioData */

export interface IAccessInfoDocument {
  access: string[];
  address: string;
  parking: string;
  phoneNumber: string;
}

export interface ITemplate {
  scenarioDesign: IScenarioDesign;
}

export interface IScenarioElseInfo {
  accessInfo: IAccessInfoDocument;
  template: ITemplate;
}

/* editable sceanrioData */

export interface IEditableScenarioData {
  variables: IScenarioVariable[];
  scenarioDesign: IScenarioDesign;
  basicInfo: IScenarioBasicInfo;
}

/* public sceanrioData */

export interface IPublicScenarioDataDocument {
  redirectUrlMap: { [key: string]: string };
}

export interface ICreateScenarioData {
  clinic: {
    privateClinicData: IClinicData;
    editableClinicData: IEditableClinicData;
    editableScenarioData: IEditableScenarioData;
    publicScenarioData: IPublicScenarioDataDocument;
  };
  scenario: {
    searchKeywords: SearchKeywords;
    nodeMap: ScenarioNodeMap;
    parsedNodeMap: ScenarioNodeMap;
    keywordsMap: ScenarioKeywordsMap;
    elseInfo: IScenarioElseInfo;
  };
}
