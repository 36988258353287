type ErrorDetail = null | {};

type InfoDetail = null | React.ErrorInfo;

export interface ILineBotManagerLogDocument {
  level: number;
  message: string;
  type: string;
  details?: { error: ErrorDetail; info: InfoDetail };
  userAgent: IUAParser.IResult;
  createdAt: firebase.firestore.FieldValue;
}

export const SYSTEM_LOG_COLLECTION_NAME = 'lineBotManagerLogs';

export type LogLevel = 0 | 1 | 2 | 3 | 4 | 5 | 100;

export const LOG_LEVELS: { [logType: string]: LogLevel } = {
  ERROR: 0,
  WARN: 1,
  INFO: 2,
  VERBOSE: 3,
  DEBUG: 4,
  SILLY: 5,
  FATAL_ERROR: 100,
};

export type LogType =
  | 'error'
  | 'warn'
  | 'info'
  | 'verbose'
  | 'debug'
  | 'silly'
  | 'fatal_error';

export const LOG_TYPES: { [logLevel: number]: LogType } = {
  [LOG_LEVELS.ERROR]: 'error',
  [LOG_LEVELS.WARN]: 'warn',
  [LOG_LEVELS.INFO]: 'info',
  [LOG_LEVELS.VERBOSE]: 'verbose',
  [LOG_LEVELS.DEBUG]: 'debug',
  [LOG_LEVELS.SILLY]: 'silly',
  [LOG_LEVELS.FATAL_ERROR]: 'fatal_error',
};

export type LogCollectionName =
  | 'errorLogs'
  | 'warnLogs'
  | 'infoLogs'
  | 'verboseLogs'
  | 'debugLogs'
  | 'sillyLogs'
  | 'errorLogs';

export const LOG_COLLECTION_NAMES: { [logType: string]: LogCollectionName } = {
  [LOG_TYPES[LOG_LEVELS.ERROR]]: 'errorLogs',
  [LOG_TYPES[LOG_LEVELS.WARN]]: 'warnLogs',
  [LOG_TYPES[LOG_LEVELS.INFO]]: 'infoLogs',
  [LOG_TYPES[LOG_LEVELS.VERBOSE]]: 'verboseLogs',
  [LOG_TYPES[LOG_LEVELS.DEBUG]]: 'debugLogs',
  [LOG_TYPES[LOG_LEVELS.SILLY]]: 'sillyLogs',
  [LOG_TYPES[LOG_LEVELS.FATAL_ERROR]]: 'errorLogs',
};

// log message
export const ERROR_BOUNDARY_CATCH_ERROR_MESSAGE =
  'Error Boundary caught an error';
export const USER_ACCESS_LOG = 'User Access';
