import Joi from '@hapi/joi';

import {
  PASSWORD_MIN_LENGTH,
  CLINIC_NAME_MAX_LENGTH,
  ACCESS_TOKEN_MAX_LENGTH,
  ACCOUNT_NAME_MAX_LENGTH,
  GREETING_MESSAGE_MAX_LENGTH,
  PREMIUM_LIN_ACCOUNT_ID_MAX_LENGTH,
  EXPLANATION_MAX_LENGTH,
  ACCESS_MAX_LENGTH,
  PARKING_OTHER_MAX_LENGTH,
  CARD_HOLDER_NAME_MAX_LENGTH,
  CAMPAIGN_CODE_MAX_LENGTH,
  SCENARIO_LABEL_MAX_LENGTH,
  VARIABLE_LABEL_MAX_LENGTH,
  VARIABLE_VALUE_MAX_LENGTH,
  IMAGE_MAX_LENGTH,
} from '../constants/config.const';
import {
  INVALID_EMAIL_MESSAGE,
  INVALID_PASSWORD_MESSAGE,
  INVALID_CLINIC_NAME_MESSAGE,
  INVALID_ACCESS_TOKEN_MESSAGE,
  INVALID_LINE_REGISTRATION_URL_MESSAGE,
  INVALID_ADRESS_MESSAGE,
  INVALID_PHONE_NUMBER_MESSAGE,
  INVALID_ACCOUNT_NAME_MESSAGE,
  INVALID_GREETING_MESSAGE_MESSAGE,
  INVALID_PREMIUM_LIN_ACCOUNT_ID_MESSAGE,
  INVALID_URL_MESSAGE,
  INVALID_EXPLANATION_MESSAGE,
  INVALID_ACCESS_MESSAGE,
  INVALID_PARKING_OTHER_MESSAGE,
  INVALID_CARD_HOLDER_NAME_MESSAGE,
  INVALID_CAMPAIGN_CODE_MESSAGE,
  INVALID_SCENARIO_LABEL_MESSAGE,
  INVALID_VARIABLE_LABEL_MESSAGE,
  INVALID_VARIABLE_VALUE_MESSAGE,
  INVALID_IMAGE_NAME_MESSAGE,
} from '../constants/error-message.const';
import { PREMIUM_LINE_ACCOUNT_ID_SUFFIX } from '../redux/scenario/scenario.state';

export interface IValidate<T> {
  (arg: T): IValidationResult;
}

interface IValidationResult {
  error: string | null;
}

const ADDRESS_REGEXP = /(...??[都道府県])((?:旭川|伊達|石狩|盛岡|奥州|田村|南相馬|那須塩原|東村山|武蔵村山|羽村|十日町|上越|富山|野々市|大町|蒲郡|四日市|姫路|大和郡山|廿日市|下松|岩国|田川|大村)市|.+?郡(?:玉村|大町|.+?)[町村]|.+?市.+?区|.+?[市区町村])(.+)/;

const generateValidatorWithMaxLength = (
  maxLength: number,
  errorMessage: string,
  allowEmpty: boolean = false,
) => (val: string): IValidationResult => {
  let schema = Joi.string()
    .max(maxLength)
    .required();
  schema = allowEmpty ? schema.allow('') : schema;
  const { error } = schema.validate(val);
  return { error: error ? errorMessage : null };
};

export const isValidEmail = (email: string): IValidationResult => {
  const schema = Joi.string()
    .email()
    .required();
  const { error } = schema.validate(email);
  return { error: error ? INVALID_EMAIL_MESSAGE : null };
};

export const isValidPassword = (password: string): IValidationResult => {
  const schema = Joi.string()
    .min(PASSWORD_MIN_LENGTH)
    .required();
  const { error } = schema.validate(password);
  return { error: error ? INVALID_PASSWORD_MESSAGE : null };
};

export const isValidName = (name: string): IValidationResult => {
  const schema = Joi.string()
    .max(CLINIC_NAME_MAX_LENGTH)
    .required();
  const { error } = schema.validate(name);
  return { error: error ? INVALID_CLINIC_NAME_MESSAGE : null };
};

export const isValidAccountName = (name: string): IValidationResult => {
  const schema = Joi.string()
    .max(ACCOUNT_NAME_MAX_LENGTH)
    .required();
  const { error } = schema.validate(name);
  return { error: error ? INVALID_ACCOUNT_NAME_MESSAGE : null };
};

export const isValidAddress = (adress: string): IValidationResult => {
  const schema = Joi.string()
    .regex(ADDRESS_REGEXP)
    .required();
  const { error } = schema.validate(adress);
  return { error: error ? INVALID_ADRESS_MESSAGE : null };
};

export const isValidPhoneNumber = (phoneNumber: string) => {
  const schema = Joi.string()
    .regex(/^(0[5-9]0[0-9]{8}|0[1-9][1-9][0-9]{7})$/)
    .required();
  const { error } = schema.validate(phoneNumber);
  return { error: error ? INVALID_PHONE_NUMBER_MESSAGE : null };
};

export const isValidLineAccessToken = (
  lineAccessToken: string,
): IValidationResult => {
  const schema = Joi.string()
    .max(ACCESS_TOKEN_MAX_LENGTH) ////TODO use regexp if possible
    .required();
  const { error } = schema.validate(lineAccessToken);
  return { error: error ? INVALID_ACCESS_TOKEN_MESSAGE : null };
};

export const isValidLineRegistrationUrl = (
  lineRegistrationUrl: string,
): IValidationResult => {
  const schema = Joi.string()
    .uri() //TODO more detail
    .required();
  const { error } = schema.validate(lineRegistrationUrl);
  return { error: error ? INVALID_LINE_REGISTRATION_URL_MESSAGE : null };
};

/*======================================
 * save credit card form
 ======================================*/
export const isValidCardHolderName = (cardHolderName: string) => {
  const schema = Joi.string()
    .regex(/^((?:[A-Za-z]+ ?){1,3})$/)
    .max(CARD_HOLDER_NAME_MAX_LENGTH)
    .required();
  const { error } = schema.validate(cardHolderName);
  return { error: error ? INVALID_CARD_HOLDER_NAME_MESSAGE : null };
};

// TODO more detailed validation
export const isValidCampaignCode = (campaignCode: string) => {
  const schema = Joi.string()
    .max(CAMPAIGN_CODE_MAX_LENGTH)
    .required()
    .allow('');
  const { error } = schema.validate(campaignCode);
  return { error: error ? INVALID_CAMPAIGN_CODE_MESSAGE : null };
};

/*======================================
 * scenario clinic form
 ======================================*/
export const isValidGreetingMessage = generateValidatorWithMaxLength(
  GREETING_MESSAGE_MAX_LENGTH,
  INVALID_GREETING_MESSAGE_MESSAGE,
);

export const PREMIUM_LINE_ACCOUNT_ID_MAX_LENGTH =
  PREMIUM_LIN_ACCOUNT_ID_MAX_LENGTH - PREMIUM_LINE_ACCOUNT_ID_SUFFIX.length;

export const isValidPremiumAccountId = (premiumLineAccountId: string) => {
  const schema = Joi.string()
    .regex(/^[A-Za-z0-9_\-.]+$/)
    .max(PREMIUM_LINE_ACCOUNT_ID_MAX_LENGTH)
    .required();
  const { error } = schema.validate(premiumLineAccountId);
  return { error: error ? INVALID_PREMIUM_LIN_ACCOUNT_ID_MESSAGE : null };
};

export const isValidUrl = (url: string) => {
  const schema = Joi.string()
    .uri()
    .required();
  const { error } = schema.validate(url);
  return { error: error ? INVALID_URL_MESSAGE : null };
};

export const isValidAccess = generateValidatorWithMaxLength(
  ACCESS_MAX_LENGTH,
  INVALID_ACCESS_MESSAGE,
  false,
);

export const isValidExplanation = generateValidatorWithMaxLength(
  EXPLANATION_MAX_LENGTH,
  INVALID_EXPLANATION_MESSAGE,
  true,
);

export const isValidParkingOther = generateValidatorWithMaxLength(
  PARKING_OTHER_MAX_LENGTH,
  INVALID_PARKING_OTHER_MESSAGE,
  false,
);

/*======================================
 * new scenario form
 ======================================*/

export const isValidScenarioLabel = generateValidatorWithMaxLength(
  SCENARIO_LABEL_MAX_LENGTH,
  INVALID_SCENARIO_LABEL_MESSAGE,
  false,
);

/*======================================
 * variables
 ======================================*/

export const isValidVariableLabel = generateValidatorWithMaxLength(
  VARIABLE_LABEL_MAX_LENGTH,
  INVALID_VARIABLE_LABEL_MESSAGE,
  false,
);

export const isValidTextVariableValue = generateValidatorWithMaxLength(
  VARIABLE_VALUE_MAX_LENGTH,
  INVALID_VARIABLE_VALUE_MESSAGE,
  false,
);

/*======================================
 * images
 ======================================*/
export const isValidImageName = generateValidatorWithMaxLength(
  IMAGE_MAX_LENGTH,
  INVALID_IMAGE_NAME_MESSAGE,
  false,
);
