import { IApplicantDocument } from '../../utils/firebase.util';

/*===========================================
 * LOAM (LINE OFFICIAL ACCOUNT MANAGER)
 *==========================================*/

/* loam
---------------------------*/
export interface ILoamState {
  lineUrl?: string;
  basicLineAccountId?: string;
}

export const INITIAL_LOAM_STATE: ILoamState = {
  lineUrl: undefined,
  basicLineAccountId: undefined,
};

/*===========================================
 * APPLICANT
 *==========================================*/

export interface IAccountNumberState {
  accountNumber?: string;
}

export const INITIAL_ACCOUNT_NUMBER = {
  accountNumber: undefined,
};

export interface IApplicantState
  extends IApplicantDocument,
    IAccountNumberState,
    ILoamState {}

const INITIAL_APPLICANT_DOCUMENT_STATE = {
  hospitalName: '',
  accountName: '',
  department: '',
  email: '',
  doctorName: '',
  address: '',
  phoneNumber: '',
  referrerId: '',
};

export const INITIAL_APPLICANT_STATE: IApplicantState = {
  ...INITIAL_LOAM_STATE,
  ...INITIAL_ACCOUNT_NUMBER,
  ...INITIAL_APPLICANT_DOCUMENT_STATE,
};
