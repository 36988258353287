import React from 'react';

import style from './complete-migration-page.module.scss';

const BOTLOGY2_URL = 'https://console.botlogy.com';

///////////////////////////////////// ROOT

type CompleteMigrationPageProps = {};

export const CompleteMigrationPage: React.FC<CompleteMigrationPageProps> = () => {
  return (
    <div className={style.root}>
      <div className={style.content}>
        <h1 className={style.title}>
          CLINIC BOTはアップデートして生まれ変わりました。
        </h1>
        <p className={style.desc}>
          CLINIC BOT 管理画面は<a href={BOTLOGY2_URL}>こちらのサイト</a>
          (https://console.botlogy.com)に移行しました。
          <br />
          今後はこちらのアドレスをブックマーク保存してお使いください。
        </p>
        <code className={style.block}>
          <span>移行後のURL : </span>
          <a href={BOTLOGY2_URL}>{BOTLOGY2_URL}</a>
        </code>
      </div>
    </div>
  );
};
